.expo-wrapper {
    display: flex;
    flex-direction: column;
    gap: min(1.38vw, 20px);
}

.expo-image-container {
    width: min(25.7vw, 370.19px);
    height: min(30.57vw, 440.35px);
    background-color: #2D3882;
    border-radius: min(1.11vw, 16px);
    overflow: hidden;
    cursor: pointer;
}

.expo-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
    transition: scale ease 0.5s;
}

.expo-image:hover {
    scale: 1.1;
}

.expo-title {
    max-width: min(25.7vw, 370.19px);
    word-wrap: break-word;
    font-family: 'Archivo-Expanded-Bold';
    font-size: min(1.66vw, 24px);
    line-height: min(1.99vw, 28.7px);
    color: black;
    letter-spacing: -1.5px;
    text-transform: uppercase;
}

/*expo banners*/

.expo-type-banner-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: min(7.7vw, 111px);
    gap: 2px;
}

.banner-header {
    font-family: 'Archivo-Expanded-SemiBold';
    font-size: min(1.66vw, 24px);
    line-height: 100%;
    color: black;
    letter-spacing: -1px;
    margin-bottom: min(2.91vw, 42px);
}

.expo-banner-wrapper {
    display: flex;
    gap: min(0.13vw, 2px);
}

.expo-left-col-wrapper {
    width: min(58.6vw, 845px);
    height: min(33.6vw, 484px);
    border-radius: min(2.22vw, 32px);
    background-color: #E84E0F;
    display: flex;
    align-items: center;
    justify-content: center;
}

.expo-left-col-content-wrapper {
    display: flex;
    gap: min(0.34vw, 5px);
}

.expo-banner-image {
    width: min(17.22vw, 248px);
    height: min(20.48vw, 295px);
    border-radius: min(1.11vw, 16px);
}

.expo-right-col-wrapper {
    width: min(32.84vw, 473px);
    height: min(33.61vw, 484px);
    border-radius: min(2.22vw, 32px);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #BA3E0C;
}

.expo-right-col-content-wrapper {
    display: flex;
    flex-direction: column;
    gap: min(2.22vw, 32px);
}

.expo-description-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: min(1.66vw, 24px);
}

.expo-banner-title {
    font-family: 'Archivo-Expanded-SemiBold';
    font-size: min(2.22vw, 32px);
    line-height: 100%;
    color: white;
    max-width: min(19.44vw, 280px);
    text-transform: uppercase;
    align-self: flex-start;
}

.expo-subtitle {
    font-family: 'Archivo Narrow';
    font-size: min(1.66vw, 24px);
    color: white;
    max-width: min(25.2vw, 363px);
}


@media screen and (max-width: 478px) {

    .expo-wrapper {
        display: flex;
        flex-direction: column;
        gap: min(3.64vw, 15px);
    }
    
    .expo-image-container {
        width: min(69.03vw, 284.43px);
        height: min(82.12vw, 338.34px);
        background-color: #2D3882;
        border-radius: min(2.98vw, 12.29px);
        overflow: hidden;
        cursor: pointer;
    }
    
    .expo-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        cursor: pointer;
        transition: scale ease 0.5s;
    }
    
    .expo-image:hover {
        scale: 1.1;
    }
    
    .expo-title {
        max-width: 100%;
        word-wrap: break-word;
        font-family: 'Archivo-Expanded-Bold';
        font-size: min(3.39vw, 14px);
        line-height: min(3.69vw, 15.23px);
        color: black;
        letter-spacing: -1.5px;
        text-transform: uppercase;
    }
    
    /*expo banners*/
    
    .expo-type-banner-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: min(22.97vw, 94.66px);
        gap: min(5.58vw, 23px);
    }
    
    .banner-header {
        font-family: 'Archivo-Expanded-SemiBold';
        font-size: min(1.66vw, 24px);
        line-height: 100%;
        color: black;
        letter-spacing: -1px;
        margin-bottom: min(2.91vw, 42px);
    }
    
    .expo-banner-wrapper {
        display: flex;
        flex-direction: column-reverse;
        gap: 3px;
    }
    
    .expo-left-col-wrapper {
        width: min(96.11vw, 396px);
        height: min(55.5vw, 229px);
        border-radius: min(2.22vw, 32px);
        background-color: #E84E0F;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .expo-left-col-content-wrapper {
        display: flex;
        gap: 2.4px;
    }
    
    .expo-banner-image {
        width: min(28.97vw, 119.38px);
        height: min(34.46vw, 142px);
        border-radius: min(1.11vw, 16px);
    }
    
    .expo-right-col-wrapper {
        width: min(96.11vw, 396px);
        height: min(65.04vw, 268px);
        border-radius: min(2.47vw, 10px);
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #BA3E0C;
    }
    
    .expo-right-col-content-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: min(7.28vw, 30px);
    }
    
    .expo-description-wrapper {
        width: auto;
        display: flex;
        flex-direction: column;
        align-items: center ;
        justify-content: center !important;
        gap: min(1.66vw, 24px);
        box-sizing: border-box;
    }
    
    .expo-banner-title {
        font-family: 'Archivo-Expanded-SemiBold';
        font-size: min(5.82vw, 24px);
        line-height: 100%;
        color: white;
        max-width: min(70.6vw, 291px);
        text-transform: uppercase;
        align-self: flex-start;
        text-align: center;
        word-wrap: break-word;
        align-self: center;
    }
    
    .expo-subtitle {
        font-family: 'Archivo Narrow';
        font-size: min(3.88vw, 16px);
        line-height: min(5.58vw, 23px);
        text-align: center;
        color: white;
        max-width: 90%;
        align-self: center;
    }
    
    
    
    
}
