.card-container {
    display: flex;
    flex-direction: column;
}

.image-container {
    position: relative;
    width: min(22.53vw, 324.44px);
    height: min(33.7vw, 485.7px);
    border-radius: min(1.28vw, 18.48px);
    cursor: pointer;
    transition: all ease 0.5s;
    overflow: hidden;
}



.bg-image {
    position: absolute;
    top: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    border-radius: min(1.28vw, 18.48px);
    object-fit: cover;
    transition: all ease 0.5s;
}

.bg-image:hover {
    scale: 1.1;
    filter: contrast(1.1);
}

.pill-row-container {
    position: relative;
    display: flex;
    gap: min(0.55vw, 8px);
    z-index: 1;
    padding-left: min(0.97vw, 14px);
    padding-top: min(0.97vw, 14px);
}

.place-pill {
    padding-left: min(1.38vw, 20px);
    padding-top: min(0.69vw, 10px);
    padding-right: min(1.38vw, 20px);
    padding-bottom: min(0.69vw, 10px);
    border-radius: min(3.47vw, 50px);
    background-color: black;
    color: white;
    font-family: 'Archivo-Expanded-Light';
    font-size: min(1.25vw, 18px);
    line-height: min(1.35vw, 19.58px);
    width: fit-content;
    letter-spacing: -2%;

}

.shop-description-container {
    display: flex;
    flex-direction: column;
    gap: min(0.55vw, 8px);
    max-width: min(22.53vw, 324.44px);
    margin-top: min(1.66vw, 24px);
}

.shop-name {
    font-family: 'Archivo-Expanded-Bold';
    font-size: min(1.66vw, 24px);
    line-height: min(1.99vw, 28.7px);
    text-transform: uppercase;
    letter-spacing: -1.2px;
    word-wrap: break-word;
}

.shop-type {
    font-family: 'Archivo-Expanded';
    font-size: min(1.66vw, 24px);
    line-height: min(2.15vw, 31px);
    letter-spacing: -0.5px;
}

.icon-row {
    display: flex;
    gap: min(0.55vw, 8px);
}

.food-icon {
    width: min(1.59vw, 23px);
    height: min(1.59vw, 23px);
}


/*shop pop up*/

.over-background-wrapper {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    overflow-y: auto;
    overflow-x: hidden;
    display: none;
    opacity: 0;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(5px) brightness(0.7);
    z-index: 90;
    transition: opacity ease 0.3s;
    transition-delay: 0s;
    
}


/**/

/**/

.pop-up-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: min(0.7vw, 10px);
    transition: opacity ease 0.5s, display ease 0s;

}

.close-pop-up-row {
    display: flex;
    width: 100%;
    justify-content: flex-end;
}

.close-pop-up-button-row {
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: opacity ease 0.3s;   
}

.close-pop-up-button-row:hover {
    opacity: 0.5;
}

.close-text {
    font-family: 'Archivo-SemiExpanded-Regular';
    color: white;
    font-size: min(0.94vw, 13.55px);
    line-height: 100%;
}

.close-icon-wrapper {
    width: min(2.58vw, 37.27px);
    height: min(2.58vw, 37.27px);
}

.pop-up-content-wrapper {
    display: flex;
    gap: 2px;
}

.pop-up-content-left-column {
    /*
    width: min(32.8vw, 473px);
    height: min(51.6vw, 744px);
    */
    width: min(24.73vw, 356.18px);
    height: min(38.9vw, 560.25px);
    border-radius: min(2.22vw, 32px);
    background-color: #37B876;
    overflow: hidden;
}


.pop-up-image {
    width: inherit;
    height: inherit;
    object-fit: cover;
}

.pop-up-content-right-column {
    /*
    width: min(58.6vw, 845px);
    height: min(51.6vw, 744px);
    */
    width: min(44.18vw, 636.31px);
    height: min(38.9vw, 560.25px);
    border-radius: min(2.22vw, 32px);
    background-color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.right-column-content-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    padding-left: min(3.4vw, 49px);
    gap: min(2.09vw, 30.12px);
}

.pill-container {
    display: flex;
    align-items: center;
    gap: min(0.55vw, 8px);
}

.popup-pill {
    /*padding-top: min(0.69vw, 20px);*/
    padding-right: min(1.38vw, 20px);
    /*padding-bottom: min(0.69vw, 20px);*/
    padding-left: min(1.38vw, 20px);
    display: flex;
    align-items: center;
    justify-content: center;
    height: min(2.77vw, 40px);
    color: white;
    font-family: 'Archivo-SemiExpanded-Regular';
    font-size: min(1.25vw, 18px);
    line-height: 100%;
    letter-spacing: -1px;
    border: 1px solid white;
    border-radius: min(3.47vw, 50px);
}

.popup-pill-smaller {
    /*padding-top: min(0.69vw, 20px);*/
    padding-right: min(1.04vw, 15.06px);
    /*padding-bottom: min(0.69vw, 20px);*/
    padding-left: min(1.04vw, 15.06px);
    display: flex;
    align-items: center;
    justify-content: center;
    height: min(2.09vw, 30.12px);
    color: white;
    font-family: 'Archivo-SemiExpanded-Regular';
    font-size: min(0.97vw, 14px);
    line-height: 100%;
    letter-spacing: -1px;
    border: 1px solid white;
    border-radius: min(2.61vw, 37.65px);
}

.pop-up-title {
    font-family: 'Archivo-Expanded-Bold';
    font-size: min(1.98vw, 28.62px);
    color: white;
    letter-spacing: -1.5px;
    text-transform: uppercase;
    max-width: min(42vw, 605px);
    word-wrap: break-word;
    overflow-x: hidden;
    overflow-y: auto;
}

.pop-up-description {
    font-family: 'Archivo-Expanded';
    color: white;
    max-width: min(27.5vw, 396px);
    font-size: min(0.97vw, 14px);
    line-height: min(1.62vw, 23.34px);
    letter-spacing: -0.5px;
    max-height: min(9.79vw, 141px);
    word-wrap: break-word;
    text-wrap: wrap;
    overflow-y: auto;
    overflow-x: hidden;
}

.pop-up-description::-webkit-scrollbar {
    width: 3px; /* Width of the scrollbar */
  }
  
  .pop-up-description::-webkit-scrollbar-track {
    background: transparent; /* Background color of the scrollbar track */
  }
  
  .pop-up-description::-webkit-scrollbar-thumb {
    background: #888; /* Background color of the scrollbar thumb */
    border-radius: 4px; /* Border radius of the scrollbar thumb */
  }
  
  .pop-up-description::-webkit-scrollbar-thumb:hover {
    background: #555; /* Background color of the scrollbar thumb on hover */
  }

  .title-pills-gap {
    gap: min(1.69vw, 24.41px);
  }
  

@media screen and (max-width: 478px) {

    .card-container {
        display: flex;
        flex-direction: column;
        width: min(41.26vw, 170px);
    }
    
    .image-container {
        position: relative;
        width: min(41.26vw, 170px);
        height: min(62vw, 255.52px);
        border-radius: min(2.34vw, 9.68px);
        cursor: pointer;
        transition: all ease 0.5s;
        overflow: hidden;
    }
    
    
    
    .bg-image {
        position: absolute;
        top: 0;
        z-index: 0;
        width: 100%;
        height: 100%;
        border-radius: min(1.28vw, 18.48px);
        object-fit: cover;
        transition: all ease 0.5s;
    }
    
    .bg-image:hover {
        scale: 1.1;
        filter: contrast(1.1);
    }
    
    .pill-row-container {
        position: relative;
        display: flex;
        gap: min(0.55vw, 8px);
        z-index: 1;
        padding-left: min(0.97vw, 14px);
        padding-top: min(0.97vw, 14px);
    }
    
    .place-pill {
        padding-left: min(2.54vw, 10.47px);
        padding-top: min(0.96vw, 3.97px);
        padding-right: min(2.54vw, 10.47px);
        padding-bottom: min(0.96vw, 3.97px);
        border-radius: min(6.35vw, 26.18px);
        background-color: black;
        color: white;
        font-family: 'Archivo-Expanded-Light';
        font-size: min(2.66vw, 11px);
        line-height: min(3.15vw, 13px);
        width: fit-content;
        letter-spacing: -2%;
    
    }
    
    .shop-description-container {
        display: flex;
        flex-direction: column;
        gap: min(1.01vw, 4.19px);
        max-width: 100%;
        width: fit-content;
        margin-top: min(1.66vw, 24px);
    }
    
    .shop-name {
        font-family: 'Archivo-Expanded-Bold';
        font-size: min(3.39vw, 14px);
        line-height: min(3.69vw, 15.23px);
        text-transform: uppercase;
        letter-spacing: -1.2px;
        word-wrap: break-word;
    }
    
    .shop-type {
        font-family: 'Archivo-Expanded';
        font-size: min(3.39vw, 14px);
        line-height: 100%;
        letter-spacing: -0.5px;
    }
    
    .icon-row {
        display: flex;
        gap: min(0.55vw, 8px);
    }
    
    .food-icon {
        width: min(3.89vw, 16.06px);
        height: min(3.89vw, 16.06px);
    }

    .food-icon-on-popup {
        width: min(6.9vw, 28.43px) !important;
        height: min(6.9vw, 28.43px) !important;
    }
    
    
    /*shop pop up*/
    
    .over-background-wrapper {
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        overflow-y: auto;
        overflow-x: hidden;
        display: none;
        opacity: 0;
        align-items: center;
        justify-content: center;
        backdrop-filter: blur(5px) brightness(0.7);
        z-index: 90;
        transition: opacity ease 0.3s;
        transition-delay: 0s;
        
    }
    
    
    /**/
    
    /**/
    
    .pop-up-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: min(0.7vw, 10px);
        transition: opacity ease 0.5s, display ease 0s;
    
    }
    
    .close-pop-up-row {
        display: flex;
        width: 100%;
        justify-content: flex-end;
    }
    
    .close-pop-up-button-row {
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: opacity ease 0.3s;   
        gap: min(2.18vw, 9px);
    }
    
    .close-pop-up-button-row:hover {
        opacity: 0.5;
    }
    
    .close-text {
        font-family: 'Archivo-SemiExpanded-Regular';
        color: white;
        font-size: min(3.39vw, 14px);
        line-height: 100%;
    }
    
    .close-icon-wrapper {
        width: min(9.87vw, 40.68px);
        height: min(9.87vw, 40.68px);
    }
    
    .pop-up-content-wrapper {
        display: flex;
        flex-direction: column;
        gap: 3px;
    }
    
    .pop-up-content-left-column {
        /*
        width: min(32.8vw, 473px);
        height: min(51.6vw, 744px);
        */
        width: min(83.49vw, 344px);
        height: min(61.65vw, 254px);
        border-radius: 10px;
        background-color: #37B876;
        overflow: hidden;
    }
    
    
    .pop-up-image {
        width: inherit;
        height: inherit;
        object-fit: cover;
    }
    
    .pop-up-content-right-column {
        /*
        width: min(58.6vw, 845px);
        height: min(51.6vw, 744px);
        */
        width: min(83.49vw, 344px);
        height: min(102vw, 421px);
        border-radius: min(2.22vw, 32px);
        background-color: black;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    
    .right-column-content-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        justify-content: flex-start;
        padding-top: min(9.95vw, 41px);
        padding-left: min(6.31vw, 26px);
        gap: min(6.06vw, 25px)
    }
    
    .pill-container {
        display: flex;
        align-items: center;
        gap: 4px;
    }
    
    .popup-pill {
        /*padding-top: min(0.69vw, 20px);*/
        padding-right: min(2.44vw, 10.08px);
        /*padding-bottom: min(0.69vw, 20px);*/
        padding-left: min(2.44vw, 10.08px);
        display: flex;
        align-items: center;
        justify-content: center;
        height: min(4.89vw, 20.17px);
        color: white;
        font-family: 'Archivo-SemiExpanded-Regular';
        font-size: min(2.91vw, 12px);
        line-height: 100%;
        letter-spacing: -0.5px;
        border: 1px solid white;
        border-radius: min(2.61vw, 37.65px);
    }
    
    .popup-pill-smaller {
        /*padding-top: min(0.69vw, 20px);*/
        padding-right: min(2.44vw, 10.08px);
        /*padding-bottom: min(0.69vw, 20px);*/
        padding-left: min(2.44vw, 10.08px);
        display: flex;
        align-items: center;
        justify-content: center;
        height: min(4.89vw, 20.17px);
        color: white;
        font-family: 'Archivo-SemiExpanded-Regular';
        font-size: min(2.91vw, 12px);
        line-height: 100%;
        letter-spacing: -0.5px;
        border: 1px solid white;
        border-radius: min(2.61vw, 37.65px);
    }
    
    .pop-up-title {
        font-family: 'Archivo-Expanded-Bold';
        font-size: min(5.82vw, 24px);
        color: white;
        letter-spacing: -1.5px;
        text-transform: uppercase;
        max-width: 70%;
        word-wrap: break-word;
        overflow-x: hidden;
        overflow-y: auto;
    }
    
    .pop-up-description {
        font-family: 'Archivo-Expanded';
        color: white;
        max-width: 70%;
        font-size: min(3.88vw, 16px);
        line-height: min(5.58vw, 23px);
        letter-spacing: -0.5px;
        max-height: min(36.16vw, 149px);
        word-wrap: break-word;
        text-wrap: wrap;
        overflow-y: auto;
        overflow-x: hidden;
    }
    
    .pop-up-description::-webkit-scrollbar {
        width: 3px; /* Width of the scrollbar */
      }
      
      .pop-up-description::-webkit-scrollbar-track {
        background: transparent; /* Background color of the scrollbar track */
      }
      
      .pop-up-description::-webkit-scrollbar-thumb {
        background: #888; /* Background color of the scrollbar thumb */
        border-radius: 4px; /* Border radius of the scrollbar thumb */
      }
      
      .pop-up-description::-webkit-scrollbar-thumb:hover {
        background: #555; /* Background color of the scrollbar thumb on hover */
      }
    
      .title-pills-gap {
        gap: min(3.91vw, 16.13px);
      }
      
    
    
    
}