
.ent-cards-row {
    display: flex;
    gap: min(1.11vw, 16px);
    padding-top: min(4.46vw, 64.25px);
    padding-bottom: min(4.46vw, 64.25px);
}

/*cards*/

.ent-card-wrapper {
    display: flex;
    flex-direction: column;
    gap: min(1.8vw, 26px);
}

.ent-card-container {
    width: min(29.79vw, 429px);
    height: min(39.4vw, 568.5px);
    border-radius: min(2.22vw, 32px);
    background-color: #E84E0F;
}

.ent-card-content-container {
    display: flex;
    flex-direction: column;
    padding-top: min(2.22vw, 32px);
    
}

.ent-card-header-wrapper {
    display: flex;
    flex-direction: column;
    gap: min(2.22vw, 32px);
    padding-left: min(2.22vw, 32px);
}

.ent-pill-row {
    display: flex;
    gap: min(0.55vw, 8px);
}

.ent-svg-paloko {
    width: min(7.88vw, 113.49px);
    height: min(2.36vw, 34.02px);
}

.ent-svg-minigolf {
    width: min(13.32vw, 191.93px);
    height: min(2.35vw, 33.94px);
}

.ent-svg-obras-breves {
    width: min(20.55vw, 296px);
    height: min(2.36vw, 34.08px);
}

.ent-card-description-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /*gap: min(1.8vw, 26px);*/
    height: min(11.1vw, 161px);
}

.ent-card-description {
    max-width: min(25.6vw, 370px);
    font-family: 'Archivo Narrow', 'Archivo-Expanded';
    word-wrap: break-word;
    font-size: min(1.66vw, 24px);
    line-height: min(2.43vw, 35px);
    
    
}

.ent-cover-wrapper {
    align-self: center;
    justify-self: center;
    margin-top: min(5.2vw, 74.98px);
}

.ent-cover {
    width: 100%;
    height: min(24.5vw, 352px);
}

.ent-cover-paloko {
    width: min(16.49vw, 237.52px);
    height: min(14.28vw, 205.68px);
}

.ent-cover-minigolf {
    width: min(12.31vw, 177.39px);
    height: min(12.31vw, 177.39px);
}

.ent-cover-obras-breves {
    width: min(16.49vw, 237.52px);
    height: min(14vw, 202.48px);
}

.ent-section-title-margin {
    margin-top: min(12.15vw, 175px);
}


/*paloko site*/

.ent-site-body-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: min(7.98vw, 115px);
    padding-bottom: min(7.77vw, 112px);
}

.ent-site-body-wrapper img {
    object-fit: cover;
}

.ent-site-header-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: min(4.86vw, 70px);
    max-width: min(59.8vw, 862px);
    word-wrap: break-word;
}

.ent-site-title {
    font-family: 'Archivo-Expanded';
    font-size: min(3.33vw, 48px);
    line-height: min(4.65vw, 67px);
    letter-spacing: -1px;
    text-align: center;
}

.ent-site-description {
    font-family: 'Archivo-Expanded';
    font-size: min(1.66vw, 24px);
    line-height: min(2.15vw, 31px);
    text-align: center;
    letter-spacing: -1px;
}

/*grid thingy*/

.ent-site-card-grid-wrapper {
    display: flex;
    gap: min(0.34vw, 5px);
    padding-top: min(7.77vw, 112px);
}

.ent-site-card-grid-wrapper {
    display: flex;
    gap: min(0.34vw, 5px);
    padding-top: min(7.77vw, 112px);
}

.ent-site-card-grid-wrapper-column {
    padding-top: min(7.77vw, 112px);
}

.square-radius {
    border-radius: min(0.56vw, 8.07px);
}

.ent-col-1 {
    width: min(22.7vw, 326.97px);
    height: min(40.92vw, 589.36px);
    border-radius: min(0.56vw, 8.07px);
    background-color: #EB5B27;
    object-fit: cover;
    overflow: hidden;
}

.ent-col-1-content {
    padding-top: min(2.43vw, 35px);
    padding-left: min(1.94vw, 28px);
    display: flex;
    flex-direction: column;
    gap: min(1.38vw, 20px);
}

.ent-col-1-title {
    font-family: 'Archivo-SemiExpanded-Bold';
    font-size: min(2.27vw, 32.81px);
    line-height: min(2.22vw, 32.1px);
    color: white;
}

.ent-col-1-description {
    font-family: 'Archivo-SemiExpanded-Regular';
    font-size: min(1.38vw, 20px);
    line-height: min(2.84vw, 41px);
    color: white;

}

.ent-col-2 {
    display: flex;
    flex-direction: column;
    gap: min(0.27vw, 4px);
    padding-top: min(4.88vw, 70.3px);
}

.ent-col-2-row-1 {
    width: min(22.7vw, 326.97px);
    height: min(20.46vw, 294.68px);
    overflow: hidden;
    background-color: #202D37;
}

.ent-col-2-row-2 {
    width: min(22.7vw, 326.97px);
    height: min(20.46vw, 294.68px);
    background-color: #EB5B27;
    object-fit: cover;
    overflow: hidden;
}

.ent-col-2-row-2-content {
    padding-top: min(2.29vw, 32.98px);
    padding-left: min(1.52vw, 21.99px);
    display: flex;
    flex-direction: column;
    gap: min(2.43vw, 35px);
}

.ent-col-3 {
    display: flex;
    flex-direction: column;
    gap: min(0.27vw, 4px);
    padding-top: min(4.02vw, 58px);
}

.ent-col-3-row-1 {
    display: flex;
    gap: min(0.27vw, 4px);
    align-items: end;
}

.ent-col-3-row-1-col-1 {
    width: min(22.7vw, 327px);
    height: min(22.5vw, 325px);
    background-color: #202D37;
    object-fit: cover;
    overflow: hidden;
}

.ent-col-3-row-1-col-2 {
    width: min(22.7vw, 327px);
    height: min(20.2vw, 291px);
    background-color: #202D37;
    object-fit: cover;
    overflow: hidden;
}

.ent-col-3-row-2 {
    width: min(45.69vw, 658px);
    height: min(16.52vw, 238px);
    background-color: #202D37;
    object-fit: cover;
    overflow: hidden;
    object-position: 10% 40%;
}

/*EntSIteCover.js*/

.ent-site-cover-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: min(2.17vw, 31.38px);
    padding-top: min(8.89vw, 128.05px);
    padding-bottom: min(9.68vw, 139.45px);
}



.ent-site-cover-pill-row {
    display: flex;
    gap: min(0.5vw, 7.31px);
}

.ent-site-pill {
    background-color: white;
    padding-left: min(1.26vw, 18.27px);
    padding-right: min(1.26vw, 18.27px);
    height: min(2.53vw, 36.55px);
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Archivo-SemiExpanded-Regular';
    font-size: min(1.18vw, 17.03px);
    line-height: min(1.28vw, 18.53px);
    color: black;
    border-radius: min(3.17vw, 45.68px);
}

.paloko-logo {
    width: min(14.72vw, 212.68px);
    height: min(4.41vw, 63.62px);
}

/**/

.call-to-events-wrapper {
    margin-top: min(7.77vw, 112px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: min(1.11vw, 16px);
}

.call-to-events-title {
    font-family: 'Archivo-Expanded-Bold';
    font-size: min(1.66vw, 24px);
    line-height: min(1.99vw, 28.7px);
    letter-spacing: -2px;
    max-width: min(31.66vw, 456px);
    word-wrap: break-word;
    color: black;
    text-align: center;
}

/*OtherEnts.js*/

.other-div-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: min(5.48vw, 79px);
    padding-top: min(5.34vw, 77px);
    padding-bottom: min(4.16vw, 60px);
}

.other-div-card-wrapper {
    display: flex;
    gap: min(1.11vw, 16px);
}

.other-div-card {
    width: min(34.72vw, 500px);
    height: min(34.72vw, 500px);
    border-radius: min(2.22vw, 32px);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: min(2.29vw, 33px);
    cursor: pointer;
    transition: opacity ease 0.3s;
}

.other-div-card:hover {
    opacity: 0.7;
}

.card-golf {
    gap: min(2.29vw, 33.03px);
}

.other-div-card-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: min(2.22vw, 32px);
}

.other-div-pill-row {
    display: flex;
    gap: min(0.55vw, 8px);
    margin-top: min(5.13vw, 74px);
}

.card-svg-title {
    width: auto;
    height: min(2.36vw, 34px);
}

.golf-img {
    width: min(31.25vw, 450px);
    height: auto;
    margin-top: -25%;
}

.obras-img {
    width: min(24.8vw, 358px);
    height: auto;
    margin-top: -15%;
}

.paloko-img {
    max-width: 50%;
    max-height: 50%;
}


/*MiniGolf.js*/

.minigolf-logo {
    width: min(25.36vw, 365.24px);
    height: auto;
}

.mosaic-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/*ObrasBreves.js*/

.obras-breves-logo {
    width: min(38.68vw, 557.12px);
    height: min(4.44vw, 64px);
}

.comprar-entradas-hover {
    transition: opacity ease 0.3s;
}

.comprar-entradas-hover:hover {
    opacity: 0.7;
}

@media screen and (max-width: 478px) {

    
.ent-cards-row {
    display: flex;
    gap: min(3.64vw, 15px);
    padding-top: min(23vw, 95px);
    padding-bottom: min(23vw, 95px);
    width: 100vw;
    overflow-y: hidden;
    overflow-x: auto;
    padding-left: 10px;
}

.ent-cards-row::-webkit-scrollbar {
    display: none;
}


/*cards*/

.ent-card-wrapper {
    display: flex;
    flex-direction: column;
    gap: min(1.8vw, 26px);
}

.final-card-margin {
    margin-right: 10px;
}

.ent-card-container {
    width: min(72.04vw, 296.82px);
    height: min(95.46vw, 393.33px);
    border-radius: min(5.3vw, 22.14px);
    background-color: #E84E0F;
}

.ent-card-content-container {
    display: flex;
    flex-direction: column;
    padding-top: min(5.37vw, 22.14px);
    
}

.ent-card-header-wrapper {
    display: flex;
    flex-direction: column;
    gap: min(5.33vw, 22px);
    padding-left: min(5.37vw, 22.14px);
}

.ent-pill-row {
    display: flex;
    gap: min(0.55vw, 8px);
}

.ent-svg-paloko {
    width: min(25.97vw, 107px);
    height: auto;
}

.ent-svg-minigolf {
    width: min(32.49vw, 133.86px);
    height: auto;
}

.ent-svg-obras-breves {
    width: min(48.54vw, 200px);
    height: auto;
}

.ent-card-description-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    /*gap: min(1.8vw, 26px);*/
    height: min(27.66vw, 114px);
    gap: min(5.58vw, 23px);
}

.ent-card-description {
    max-width: min(62.13vw, 256px);
    font-family: 'Archivo Narrow', 'Archivo-Expanded';
    word-wrap: break-word;
    font-size: min(3.88vw, 16px);
    line-height: min(5.58vw, 23px);
    text-align: center;
    
}

.ent-cover-wrapper {
    align-self: center;
    justify-self: center;
    margin-top: min(6.91vw, 28.51px);
}

.ent-cover {
    width: 100%;
    height: min(55.8vw, 230px);
}

.ent-cover-paloko {
    width: min(16.49vw, 237.52px);
    height: min(14.28vw, 205.68px);
}

.ent-cover-minigolf {
    width: min(12.31vw, 177.39px);
    height: min(12.31vw, 177.39px);
}

.ent-cover-obras-breves {
    width: min(16.49vw, 237.52px);
    height: min(14vw, 202.48px);
}

.ent-section-title-margin {
    margin-top: min(31.55vw, 130px);
}


/*paloko site*/

.ent-site-body-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: min(7.98vw, 115px);
    padding-bottom: min(16.99vw, 70px);
}

.ent-site-header-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: min(4.86vw, 70px);
    max-width: min(59.8vw, 862px);
    word-wrap: break-word;
}

.ent-site-title {
    font-family: 'Archivo-Expanded';
    font-size: min(4.36vw, 18px);
    line-height: min(7.28vw, 30px);
    letter-spacing: -1px;
    text-align: center;
}

.ent-site-description {
    font-family: 'Archivo-Expanded';
    font-size: min(3.39vw, 14px);
    line-height: min(6.96vw, 28.7px);
    text-align: center;
    letter-spacing: -1px;
}

/*grid thingy*/

.ent-site-card-grid-wrapper {
    display: flex;
    gap: 4px;
    padding-top: min(11.4vw, 47px);
    flex-direction: column;
    width: min(92.7vw, 382px);
}

.square-radius {
    border-radius: 8px;
}

.ent-col-1 {
    width: 100%;
    height: min(109vw, 453px);
    border-radius: 8px;
    background-color: #EB5B27;
}

.ent-col-1-content {
    padding-top: min(8.49vw, 35px);
    padding-left: min(8.49vw, 35px);
    display: flex;
    flex-direction: column;
    gap: min(1.38vw, 20px);
}

.ent-col-1-title {
    font-family: 'Archivo-SemiExpanded-Bold';
    font-size: min(7.96vw, 32.81px);
    line-height: min(7.79vw, 32.1px);
    color: white;
}

.ent-col-1-description {
    font-family: 'Archivo-SemiExpanded-Regular';
    font-size: min(4.85vw, 20px);
    line-height: min(9.95vw, 41px);
    color: white;

}

.ent-col-2 {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding-top: 0px;
    width: 100%;
    order: 2;
}

.ent-col-2-row-1 {
    width: 100%;
    height: min(70.6vw, 291px);
    overflow: hidden;
    background-color: #202D37;
}

.ent-col-2-row-2 {
    width: 100%;
    height: min(71.35vw, 294px);
    background-color: #EB5B27;
}

.ent-col-2-row-2-content {
    padding-top: min(8vw, 33px);
    padding-left: min(6.31vw, 26px);
    display: flex;
    flex-direction: column;
    gap: min(2.43vw, 35px);
}

.ent-col-3 {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding-top: 0px;
    order: 1;
}

.ent-col-3-row-1 {
    display: flex;
    gap: 4px;
    align-items: initial;
    flex-direction: column;
    width: 100%;
}

.ent-col-3-row-1-col-1 {
    width: 100%;
    height: min(57.7vw, 238px);
    background-color: #202D37;
}

.ent-col-3-row-1-col-2 {
    width: 100%;
    height: min(57.7vw, 238px);
    background-color: #202D37;
}

.ent-col-3-row-2 {
    width: 100%;
    height: min(57.7vw, 238px);
    background-color: #202D37;
}

/*EntSIteCover.js*/

.ent-site-cover-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: min(13.83vw, 57px);
    padding-top: min(13.83vw, 57px);
    padding-bottom: min(26.9vw, 111px);
}



.ent-site-cover-pill-row {
    display: flex;
    gap: min(1.77vw, 7.31px);
}

.ent-site-pill {
    background-color: white;
    padding-left: min(4.43vw, 18.27px);
    padding-right: min(4.43vw, 18.27px);
    height: min(8.87vw, 36.55px);
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Archivo-SemiExpanded-Regular';
    font-size: min(3.39vw, 14px);
    line-height: min(3.6vw, 15px);
    color: black;
    border-radius: min(11.08vw, 45.68px);
}

.paloko-logo {
    width: auto;
    height: min(6.45vw, 26.6px);
}

/**/

.call-to-events-wrapper {
    margin-top: min(10.92vw, 45px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: min(3.88vw, 16px);
}

.call-to-events-title {
    font-family: 'Archivo-Expanded-Bold';
    font-size: min(3.88vw, 16px);
    line-height: min(6.96vw, 28.7px);
    letter-spacing: -1px;
    max-width: min(73.7vw, 304px);
    word-wrap: break-word;
    color: black;
    text-align: center;
}

/*OtherEnts.js*/

.other-div-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: min(13.8vw, 57px);
    padding-top: min(18vw, 77px);
    padding-bottom: min(20.8vw, 86px);
}

.other-div-card-wrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.other-div-card {
    width: min(86.65vw, 357px);
    height: min(86.65vw, 357px);
    border-radius: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: min(2.29vw, 33px);
    cursor: pointer;
    transition: opacity ease 0.3s;
}

.other-div-card:hover {
    opacity: 0.7;
}

.card-golf {
    gap: min(2.29vw, 33.03px);
}

.other-div-card-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: min(5.54vw, 22.85px);
}

.other-div-pill-row {
    display: flex;
    gap: min(0.55vw, 8px);
    margin-top: min(12.8vw, 52.84px);
}

.card-svg-title {
    width: auto;
    height: min(7.22vw, 29.78px);
}

.golf-img {
    width: min(61.8vw, 255px);
    height: auto;
    margin-top: -10%;
}

.obras-img {
    width:  min(61.8vw, 255px);
    height: auto;
    margin-top: -10%;
}

/*MiniGolf.js*/

.minigolf-logo {
    width: 305px;
    height: auto;
}

.mosaic-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/*ObrasBreves.js*/

.obras-breves-logo {
    width: min(38.68vw, 557.12px);
    height: min(4.44vw, 64px);
}

.insta-mobile-margin {
    margin-bottom: 58px;
}
    
}