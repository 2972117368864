.qsm-header-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: min(10vw, 144px);
    position: relative;
}

.qsm-header {
    font-family: 'Archivo-Expanded';
    font-size: min(2.22vw, 31px);
    line-height: min(4.23vw, 61px);
    color: white;
    letter-spacing: -1px;
    text-align: center;
    max-width: min(59.79vw, 861px);
    z-index: 1;
}

.qsm-header-bg-svg-wrapper {
    position: absolute;
    width: 100vw;
    height: 100%;
    z-index: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.qsm-header-bg-svg {
    margin-top: -5%s;
}

.section-title {
    z-index: 1;
}

.qsm-slide-img-container {
    width: min(91.66vw, 1320px);
    height: min(46.87vw, 675px);
    border-radius: min(1.38vw, 20px);
    overflow: hidden;
    
}

.qsm-slide-img {
    width: min(91.66vw, 1320px);
    height: min(46.87vw, 675px);
    object-fit: cover;
}

.splide-qsm-wrapper {
    margin-top: min(6.8vw, 98px);
}

.buttons-margin {
    margin-top: min(3.26vw, 47px);
}

.qsm-onplace-button {
    background-color: #20B2EB;
}


.qsm-body-description-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: min(6.8vw, 98px);
}

.qsm-body-description {
    font-family: 'Archivo-Expanded';
    font-size: min(1.66vw, 24px);
    /*line-height: min(2.15vw, 31px);*/
    line-height: min(4.23vw, 61px);
    color: white;
    text-align: center;
    max-width: min(61.52vw, 886px);
    user-select: none;
}


.qsm-cards-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
    padding-top: min(17.36vw, 250px);
}

.qsm-card-row {
    display: flex;
    gap: 4px;
}

.qsm-left-card {
    width: min(29.9vw, 431px);
    height: min(28.74vw, 414px);
    border-radius: min(2.03vw, 29.27px);
    display: flex;
    align-items: center;
    justify-content: center;
}


.qsm-left-card-text {
    font-family: 'Archivo-Expanded-Bold';
    font-size: min(2.63vw, 38px);
    letter-spacing: -1px;
    color: white;
}

.qsm-right-card {
    width: min(61.59vw, 887px);
    height: min(28.74vw, 414px);
    border-radius: min(2.03vw, 29.27px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
}

.qsm-right-card-text {
    display: flex;
    flex-direction: column;
    font-size: min(1.25vw, 18px);
    line-height: min(2.29vw, 33px);
    text-align: center;
    color: white;
    max-width: min(33.95vw, 489px);
    
}

.right-card-max-w {
    max-width: min(40vw, 577px);
}

.qsm-bold {
    font-family: 'Archivo-Expanded-Bold';
    
}

.qsm-normal {
    font-family: 'Archivo-Expanded';
}

.qsm-second-header-padding {
    padding-top: min(17.36vw, 250px);
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-row {
    display: flex;
}

.qsm-mosaico-wrapper {
    display: flex;
    gap: 4px;
    padding-top: min(17.36vw, 250px);
    
}

.qsm-tile {
    background-color: #EB5B27;
    display: flex;
    flex-direction: column;
    border-radius: min(0.55vw, 8px);
}

.qsm-tile-content {
    padding-left: min(1.94vw, 28px);
    padding-top: min(2.7vw, 39px);
    padding-right: min(1.94vw, 28px);
    padding-bottom: min(2.43vw, 35px);
    text-wrap: wrap;
    display: flex;
    flex-direction: column;
    gap: min(1.38vw, 20px);
}

.qsm-tile-title {
    font-family: 'Archivo-Expanded-Bold';
    font-size: min(2.22vw, 32px);
    line-height: 100%;
    letter-spacing: -1px;
    color: white;
    text-wrap: wrap;
}

.qsm-tile-description {
    font-family: 'Archivo-Expanded-Light';
    font-size: min(1.25vw, 18px);
    line-height: min(2.46vw, 35.52px);
    letter-spacing: -1px;
    color: white;
}

.qsm-mosaico-col-1 {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.tile-def-width {
    width: min(22.7vw, 327px);
}

.tile-1 {
    width: min(22.7vw, 327px);
    height: min(40.92vw, 589.36px);
}

.qsm-mosaico-row-1 {
    display: flex;
    gap: 4px;
    align-items: flex-end;
}

.tile-2 {
    background-color: #BA3E0C;
}

.tile-3 {
    width: min(45.65vw, 657.45px);
    height: min(18.26vw, 263px);
    box-sizing: border-box;
}

.qsm-row-inner-col {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.tile-4 {
    width: min(27.5vw, 396px);
    height: min(18.26vw, 263px);
    background-color: #BA3E0C;
}

.qsm-map-margin {
    margin-top: min(5.62vw, 81px);
}


@media screen and (max-width: 478px) {

    .qsm-header-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: min(14.81vw, 61px);
        position: relative;
    }
    
    .qsm-header {
        font-family: 'Archivo-Expanded';
        font-size: min(4.36vw, 18px);
        line-height: min(7.28vw, 30px);
        color: white;
        letter-spacing: -1px;
        text-align: center;
        max-width: 344px;
        z-index: 1;
    }
    
    .qsm-header-bg-svg-wrapper {
        position: absolute;
        width: 100vw;
        height: 180px;
        z-index: 0;
        display: none;
        align-items: center;
        justify-content: center;
        overflow: visible;
    }
    
    .qsm-header-bg-svg {
        margin-top: -5%s;
    }
    
    .section-title {
        z-index: 1;
    }
    
    .qsm-slide-img-container {
        width: min(92.71vw, 382px);
        height: min(84.7vw, 349px);
        border-radius: min(1.38vw, 20px);
        overflow: hidden;
        
    }
    
    .qsm-slide-img {
        width: inherit;
        height: inherit;
        object-fit: cover;
    }
    
    .splide-qsm-wrapper {
        margin-top: min(13.34vw, 55px);
    }
    
    .buttons-margin {
        margin-top: min(3.26vw, 47px);
    }
    
    .qsm-onplace-button {
        background-color: #20B2EB;
    }
    
    
    .qsm-body-description-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: min(21.6vw, 89px);
    }
    
    .qsm-body-description {
        font-family: 'Archivo-Expanded';
        font-size: min(3.39vw, 14px);
        /*line-height: min(5.58vw, 23px);*/
        line-height: min(7.28vw, 30px);
        color: white;
        text-align: center;
        max-width: 90%;
        user-select: none;
    }
    
    
    .qsm-cards-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 25px;
        padding-top: min(21.6vw, 89px);
    }
    
    .qsm-card-row {
        display: flex;
        flex-direction: column;
        gap: 3px;
    }
    
    .qsm-left-card {
        width: min(96.11vw, 396px);
        height: min(56.7vw, 234px);
        border-radius: min(2.03vw, 29.27px);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    
    .qsm-left-card-text {
        font-family: 'Archivo-Expanded-Bold';
        font-size: min(9.22vw, 38px);
        letter-spacing: -1px;
        color: white;
    }
    
    .qsm-right-card {
        width: min(96.11vw, 396px);
        height: min(56.7vw, 234px);
        border-radius: min(2.03vw, 29.27px);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        
    }

    .left-card-order-mobile {
        flex-direction: column-reverse;
    }
    
    .qsm-right-card-text {
        display: flex;
        flex-direction: column;
        font-size: min(2.91vw, 12px);
        line-height: 23px;
        text-align: center;
        color: white;
        max-width: 90%;
        
    }
    
    .right-card-max-w {
        max-width: 90%;
    }
    
    .qsm-bold {
        font-family: 'Archivo-Expanded-Bold';
        
    }
    
    .qsm-normal {
        font-family: 'Archivo-Expanded';
    }
    
    .qsm-second-header-padding {
        padding-top: min(21.6vw, 89px);
    }
    
    .flex-column {
        display: flex;
        flex-direction: column;
    }
    
    .flex-row {
        display: flex;
    }
    
    .qsm-mosaico-wrapper {
        display: flex;
        gap: 4px;
        padding-top: min(17.36vw, 250px);
        
    }
    
    .qsm-tile {
        background-color: #EB5B27;
        display: flex;
        flex-direction: column;
        border-radius: min(0.55vw, 8px);
    }
    
    .qsm-tile-content {
        padding-left: min(1.94vw, 28px);
        padding-top: min(2.7vw, 39px);
        padding-right: min(1.94vw, 28px);
        padding-bottom: min(2.43vw, 35px);
        text-wrap: wrap;
        display: flex;
        flex-direction: column;
        gap: min(1.38vw, 20px);
    }
    
    .qsm-tile-title {
        font-family: 'Archivo-Expanded-Bold';
        font-size: min(2.22vw, 32px);
        line-height: 100%;
        letter-spacing: -1px;
        color: white;
        text-wrap: wrap;
    }
    
    .qsm-tile-description {
        font-family: 'Archivo-Expanded-Light';
        font-size: min(1.25vw, 18px);
        line-height: min(2.46vw, 35.52px);
        letter-spacing: -1px;
        color: white;
    }
    
    .qsm-mosaico-col-1 {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }
    
    .tile-def-width {
        width: min(22.7vw, 327px);
    }
    
    .tile-1 {
        width: min(22.7vw, 327px);
        height: min(40.92vw, 589.36px);
    }
    
    .qsm-mosaico-row-1 {
        display: flex;
        gap: 4px;
        align-items: flex-end;
    }
    
    .tile-2 {
        background-color: #BA3E0C;
    }
    
    .tile-3 {
        width: min(45.65vw, 657.45px);
        height: min(18.26vw, 263px);
        box-sizing: border-box;
    }
    
    .qsm-row-inner-col {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }
    
    .tile-4 {
        width: min(27.5vw, 396px);
        height: min(18.26vw, 263px);
        background-color: #BA3E0C;
    }
    
    .qsm-map-margin {
        margin-top: min(21.6vw, 89px);
    }
    
    
    
}