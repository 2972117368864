

.react-pdf__Page__canvas {
    margin: 0 auto;
    width: 100% !important;
    height: 100% !important;
}

.react-pdf__Page__svg {
    margin: 0 auto;
    width: 100% !important;
    height: 100% !important;   
}

.svgwrapper svg {
    width: 100% !important;
    height: 100% !important;
}

