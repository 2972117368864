@font-face { /*700 weight?*/
  font-family: 'Archivo-Expanded-SemiBold';
  src: url('./assets/fonts/Archivo_Expanded/Archivo_Expanded-SemiBold.ttf');
}

@font-face { /*500 weight, same as medium*/
  font-family: 'Archivo-Expanded';
  src: url('./assets/fonts/Archivo_Expanded/Archivo_Expanded-Regular.ttf');
}

@font-face { /*400 weight*/
  font-family: 'Archivo-Expanded-Light';
  src: url('./assets/fonts/Archivo_Expanded/Archivo_Expanded-Light.ttf');
}

@font-face {
  font-family: 'Archivo-Expanded-Bold';
  src: url('./assets/fonts/Archivo_Expanded/Archivo_Expanded-Bold.ttf');
}

@font-face {
  font-family: 'Archivo-Expanded-Black';
  src: url('./assets/fonts/Archivo_Expanded/Archivo_Expanded-Black.ttf');
}

@font-face {
  font-family: 'Mont-Regular';
  src: url('./assets/fonts/Mont/Mont-Regular.ttf');
}

@font-face {
  font-family: 'Archivo-SemiExpanded-Regular';
  src: url('./assets/fonts/Archivo_SemiExpanded/Archivo_SemiExpanded-Regular.ttf');
}

@font-face {
  font-family: 'Archivo-SemiExpanded-Bold';
  src: url('./assets/fonts/Archivo_SemiExpanded/Archivo_SemiExpanded-Bold.ttf');
}

.section-title {    
  font-family: 'Archivo-Expanded-Bold';
  color: #8F8F8F;
  font-size: min(1.66vw, 24px);
  letter-spacing: -1px;
}

.section-title-margin {
  margin-bottom: min(2.77vw, 40px);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.centering-wrapper {
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.max-width-container {
  width: auto;
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.max-width-container-1920 {
  width: auto;
  max-width: 1920px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.centering-wrapper-padding {
  padding-bottom: min(11.45vw, 165px);
}

.hide-desktop {
  display: none !important;
}

@media screen and (max-width: 478px) {
  .hide-mobile {
    display: none !important;
  }

  .hide-desktop {
    display: flex !important;
  }

  .section-title {    
    font-family: 'Archivo-Expanded-Bold';
    color: #8F8F8F;
    font-size: min(3.88vw, 16px);
    letter-spacing: -1px;
  }
  
  .section-title-margin {
    margin-bottom: min(6vw, 25px);
  }
}