.onplace-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: min(53px, 3.7vw);
}

.onplace-header-container {
    position: relative;
    width: 100vw;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    overflow: hidden;
    
}

.onplace-header-absolute-container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 0;
}

.onplace-header-title {
    font-family: 'Archivo-Expanded';
    font-size: min(3.33vw, 48px);
    color: #053358;
    letter-spacing: -1px;
}

.buttons-container {
    position: relative;
    display: flex;
    align-items: center;
    gap: min(0.67vw, 9.68px);
    padding-right: min(4.16vw, 60px);
    z-index: 90;
}

.onplace-button {
    width: min(5.6vw, 80.66px);
    height: min(5.6vw, 80.66px);
    border-radius: 100%;
    background-color: #E84E0F;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color ease 0.4s;
}

.onplace-button:hover{
    background-color: black;
}

.carousel-image {
    width: 92.15vw;
    max-width: 1327px;
    /*height: min(55.5vw, 800px);*/
    height: min(37.29vw, 537px);
    object-fit: cover;
    align-self: center;
    border-radius: 10px;
    user-select: none;
}

.expo-act-gap {
    /*gap: min(2.22vw, 32px);*/
    gap: min(3.12vw, 45px);
    padding-top: min(7.7vw, 111px);
}

.onplace-splide-container {
    /*margin-top: min(7.7vw, 111px);*/
    width: min(92.15vw, 1327px);
}

.onplace-description-container {
    max-width: 45%;
}

.onplace-description {
    font-family: 'Archivo-Expanded';
    font-size: min(1.66vw, 24px);
    line-height: min(2.15vw, 31px);
    text-align: center;
    letter-spacing: -1px;
}

@media screen and (max-width: 478px) {

    .onplace-description-container {
        max-width: 70%;
    }

    .onplace-description {
        font-family: 'Archivo-Expanded';
        font-size: min(3.39vw, 14px);
        line-height: min(5vw, 21px);
        text-align: center;
        letter-spacing: -1px;
    }

    .onplace-spldie-container {
        width: 100vw;
        overflow: hidden;
    }

    .onplace-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: min(7.7vw, 111px);
    }
    
    .onplace-header-container {
        position: relative;
        width: 100vw;
        max-width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        overflow: hidden;
        
    }
    
    .onplace-header-absolute-container {
        position: static;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 0;
    }
    
    .onplace-header-title {
        font-family: 'Archivo-Expanded';
        font-size: min(4.36vw, 18px);
        color: #053358;
        letter-spacing: -1px;
    }
    
    .buttons-container {
        position: relative;
        display: flex;
        align-items: center;
        gap: min(0.67vw, 9.68px);
        padding-right: 0px;
        z-index: 0;
    }
    
    .onplace-button {
        width: min(14vw, 58px);
        height: min(14vw, 58px);
        border-radius: 100%;
        background-color: #E84E0F;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: background-color ease 0.4s;
    }
    
    .onplace-button:hover{
        background-color: black;
    }
    
    .carousel-image {
        width: 100vw;
        max-width: 1327px;
        /*height: min(55.5vw, 800px);*/
        height: min(95.8vw, 395px);
        object-fit: cover;
        align-self: center;
        border-radius: 10px;
        user-select: none;
        border-radius: 0px;
    }
    
    .expo-act-gap {
        gap: min(6vw, 25px);
        padding-top: min(15.77vw, 65px);
    }
    
}