

.centering-container {
    background-image: url('../../assets/imgs/landing/landing-bg.webp');
    background-size: cover;
    background-position: center;
    background-color: black;
    display: flex;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    align-items: center;
    justify-content: center;
}

.message-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Archivo-Expanded';
    color: white;
}

.ronda-logo {
    margin-top: 19px;
}

.welcome-text {
    
    font-weight: 500;
    font-size: 14px;
    line-height: 19.04px;
    letter-spacing: 15%;    
}

.welcome-subtext {
    margin-top: 24px;
    font-size: 24px;
    line-height: 31px;
    letter-spacing: -2%;
    text-align: center;

}

.begin-experience-button {
    margin-top: 54px;
    padding-top: 10px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 10px;
    color: black;
    background-color: white;
    border-radius: 200px;
    font-weight: 600;
    font-size: 16px;
    line-height: 37.47px;
    letter-spacing: -4%;
    cursor: pointer;
    transition: all ease 0.3s;
}

.begin-experience-button:hover {
    background-color: black;
    color: white;
}

.content-warning-text {
    margin-top: 24px;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -6%;
    text-align: center;
}


