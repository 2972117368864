
    .mobile-menu-button-wrapper {
        display: none;
    }

    .mobile-menu-bg-wrapper {
        display: none;
    }


@media screen and (max-width: 478px) {
    

.mobile-menu-button-wrapper {
    width: 100vw;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 10px;
    z-index: 999;
}

.mobile-menu-button {
    width: min(53.39vw, 220px);
    height: min(16.74vw, 69px);
    border-radius: min(13.34vw, 55px);
    background-color: white;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    
    
}

.mobile-menu-text {
    font-size: min(3.39vw, 14px);
    line-height: min(6.96vw, 28.7px);
    font-family: 'Archivo-Expanded';
}

.mobile-menu-bg-wrapper {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    backdrop-filter: blur(10px);
    z-index: 9999;
    opacity: 1;
    transition: opacity ease 0.3s;
}


.mobile-menu-container {
    width: min(94.17vw, 388px);
    height: auto;
    border-radius: 50px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-sizing: border-box;
    padding-top: min(16.26vw, 67px);
    padding-bottom: min(16.26vw, 67px);
    
}


.mobile-menu-content-container {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    gap: 2px;
}

.mobile-menu-first-row {
    width: min(41.99vw, 173px);
    height: min(15.77vw, 65px);
    border-radius: 8px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: min(4.85vw, 20px);
    background-color: #D5D5D5;
    color: #8F8F8F;
    font-family: 'Archivo-Expanded-Bold';
    text-transform: uppercase;
    font-size: min(3.88vw, 16px);
}

.mobile-menu-link-row {
    width: min(84.22vw, 347px);
    height: min(15.77vw, 65px);
    border-radius: 8px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: min(4.85vw, 20px);
    padding-right: min(4.85vw, 20px);
    background-color: #D5D5D5;
    color: black;
    font-family: 'Archivo-Expanded-Bold';
    text-transform: uppercase;
    font-size: min(3.88vw, 16px);
}

.close-menu-wrapper {
    width: 100%;
    padding-top: min(18.67vw, 76.93px);
    display: flex;
    align-items: center;
    justify-content: center;
}

}