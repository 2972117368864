.our-brands-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: min(3.33vw, 48px);
    margin-top: min(14.3vw, 206px);
}

.our-brands-title {
    font-family: 'Archivo-Expanded-Bold';
    font-size: min(1.66vw, 24px);
    color: #8F8F8F;
    letter-spacing: -6%;
}

.brand-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 4px;
}

.brand-item {
    width: min(18vw, 259.96px);
    height: min(18vw, 259.96px);
    transition: opacity ease 0.2s;
    background-color: #1A1A1A;
    border-radius: 15px;
    overflow: hidden;
}

.brand-item:hover {
    opacity: 0.9;
}

/*
@media screen and (max-width: 1440px) {
    .brand-grid {
        grid-template-columns: repeat(4, 1fr);
        row-gap: 5px;
        column-gap: 5px;
    }
}

@media screen and (max-width: 1024px) {
    .brand-grid {
        grid-template-columns: repeat(3, 1fr);
    }
}
*/


@media screen and (max-width: 478px) {

    .our-brands-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: min(6vw, 25px);
        margin-top: min(31.55vw, 130px);
    }
    
    .our-brands-title {
        font-family: 'Archivo-Expanded-Bold';
        font-size: min(1.66vw, 24px);
        color: #8F8F8F;
        letter-spacing: -6%;
    }
    
    .brand-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 2.2px;
    }
    
    .brand-item {
        width: min(27.47vw, 113.2px);
        height: min(27.47vw, 113.2px);
        transition: opacity ease 0.2s;
    }

    .brand-item svg {
        max-width: 100%;
        max-height: 100%;
    }
    
    .brand-item:hover {
        opacity: 0.9;
    }
    
}