.banner-bg {
    width: 100vw;
    /*ver como calza altura estatica con el resto del diseño*/
    height: min(4.7vw, 68px);
    /*height: 68px;*/
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity .5s ease;
    cursor: pointer;
    overflow: hidden;
}

.banner-bg:hover {
    opacity: 0.8;
}

.banner-title-container {
    display: flex;
    gap: min(3.3vw, 48px);
    align-items: center;
}

.banner-title {
    font-family: 'Archivo-Expanded-Light';
    font-size: min(1.1vw, 16px);
    font-weight: 400;
    line-height: 100%;
    color: white;
}

.visit-button {
    padding-left: min(1.3vw, 20px);
    padding-top: min(0.5vw, 5px);
    padding-right: min(1.3vw, 20px);
    padding-bottom: min(0.5vw, 5px);
    border-radius: min(3.4vw, 50px);
    border: solid 1px;
    border-width: 1px;
    border-color: white;
    color: white;
    font-family: 'Archivo-Expanded-Light';
    font-size: min(1.25vw, 18px);
    line-height: min(1.35vw, 19.5px);
}

.nav-mid-logo {
    width: min(8.05vw, 116px);
    height: auto;
}

.nav-item {
    transition: opacity .5s ease;
}

.nav-item:hover {
    opacity: .5;
}


@media screen and (max-width: 478px) {
    .banner-bg {
        width: 100vw;
        /*ver como calza altura estatica con el resto del diseño*/
        height: min(12.13vw, 50px);
        /*height: 68px;*/
        display: flex;
        justify-content: center;
        align-items: center;
        transition: opacity .5s ease;
        cursor: pointer;
        overflow: hidden;
    }
    
    .banner-bg:hover {
        opacity: 0.8;
    }
    
    .banner-title-container {
        display: flex;
        gap: min(3.3vw, 48px);
        align-items: center;
    }
    
    .banner-title {
        font-family: 'Archivo-Expanded-Light';
        font-size: min(3.39vw, 14px);
        font-weight: 400;
        line-height: 100%;
        color: white;
    }
    
    .visit-button {
        padding-left: min(1.3vw, 20px);
        padding-top: min(0.5vw, 5px);
        padding-right: min(1.3vw, 20px);
        padding-bottom: min(0.5vw, 5px);
        border-radius: min(3.4vw, 50px);
        border: solid 1px;
        border-width: 1px;
        border-color: white;
        color: white;
        font-family: 'Archivo-Expanded-Light';
        font-size: min(1.25vw, 18px);
        line-height: min(1.35vw, 19.5px);
    }
    
    .nav-mid-logo {
        width: min(8.05vw, 116px);
        height: auto;
    }
    
    .nav-item {
        transition: opacity .5s ease;
    }
    
    .nav-item:hover {
        opacity: .5;
    }

    
    
    
}