
.ronda-plan-container {
    display: flex;
    gap: min(0.2vw, 3px);
    padding-top: min(3.47vw, 50.05px);
}

.ronda-plan-column-1 {
    display: flex;
    flex-direction: column;
    gap: min(0.2vw, 3px);
}

.ronda-plan-row-1 {
    display: flex;
    gap: min(0.2vw, 3px);
}

.ronda-plan-row-2 {
    display: flex;
    gap: min(0.2vw, 3px);
}

.cover-eating {
    width: min(33.6vw, 485px);
    height: min(25vw, 361px);
}

.cover-pool-image-wrapper {
    width: min(18.4vw, 265px);
    height: min(25.05vw, 360.8px);
    border-radius: min(0.55vw, 8px);
    overflow: hidden;
    object-fit: cover;
}

.cover-pool {
    width: 100%;
    height: 100%;
    border-radius: min(0.55vw, 8px);
    object-fit: cover;
}

.ronda-plan-column-2 {
    width: min(29.3vw, 432px);
    height: min(50.41vw, 726px);
    overflow: hidden;
    object-fit: cover;
}

.cover-girl {
    /*width: min(26.8vw, 387px);
    width: min(29.3vw, 423px);
    height: min(50.41vw, 726px);*/
    /*border-radius: min(2.22vw, 32px);*/
    width: 100%;
    height: 100%;
    border-radius: min(0.55vw, 8px);
    object-fit: cover;
}

.ronda-plan-description-column-wrapper {
    /*width: min(30.7vw, 443px);*/
    width: min(26.7vw, 386px);
    height: min(50.41vw, 726px);
    /*border-radius: min(2.22vw, 32px);*/
    border-radius: min(0.55vw, 8px);
    display: flex;
    align-items: center;
    background-color: #212121;
}

.ronda-plan-description-container {
    display: flex;
    flex-direction: column;
    gap: min(2.22vw, 32px);
    padding-left: min(3.05vw, 44px);
    max-width: min(19.1vw, 276px);
}

.plan-description-title {
    font-family: 'Archivo-Expanded-Bold';
    font-size: min(2.63vw, 38px);
    line-height: min(2.87vw, 41.34px);
    text-transform: uppercase;
    letter-spacing: -1.9px;
    color: white;
}

.plan-description-subtitle {
    font-family: 'Archivo Narrow';
    font-size: min(1.66vw, 24px);
    line-height: min(2.43vw, 35px);
    color: white;
}

/*Que es ronda*/

.que-es-ronda-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: min(7.56vw, 109px);
}

.que-es-ronda-header-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.qer-title-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: min(2.7vw, 39px);
}

.que-es-ronda-title {
    font-family: 'Archivo-Expanded-Bold';
    font-size: min(1.66vw, 24px);
    line-height: 100%;
    color: #8F8F8F;
    letter-spacing: -1.5px;
}

.qer-description {
    font-family: 'Archivo-Expanded';
    font-size: min(2.22vw, 32px);
    line-height: min(4.23vw, 61px);
    letter-spacing: -1px;
    color: white;
    text-align: center;
}

.spinner-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: min(1.52vw, 22px);
    padding-top: min(2.22vw, 32px);
    height: min(19.86vw, 286px);
    width: 100%;
}

.spinner-container {
    width: 100vw;
    height: min(7.69vw, 110.8px);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.background-marquee {
    position: absolute;
    top: 0;
    z-index: 0;
}

.marquee-logo-wrapper {
    width: 100vw;
    height: min(19.86vw, 286px);
    z-index: 0;
}

.cards-container {
    display: flex;
    flex-direction: column;
    gap: min(0.27vw, 4px);
}

/*conoce el espacio*/

.conoce-el-espacio-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: min(11.7vw, 169px);
}

.espacio-img-container {
    display: flex;
    gap: min(1.11vw, 16px);
    margin-top: min(3.33vw, 48px);
}

.espacio-img-1 {
    width: min(42.27vw, 652px);
    height: min(47.2vw, 680px);
    border-radius: min(2.22vw, 32px);
    object-fit: cover;
}

.espacio-column-2-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: min(1.11vw, 16px);
}

.espacio-grid-item {
    width: min(22vw, 318px);
    height: min(23vw, 331.66px);
    border-radius: min(2.22vw, 32px);   
    object-fit: cover; 
}



/**/

.near-footer-text {
    font-family: 'Archivo-Expanded';
    font-size: min(2.22vw, 32px);
    line-height: min(4.23vw, 61px);
    text-align: center;
    color: white;
}


@media screen and (max-width: 478px) {


    
.ronda-plan-column-2 {
    order: 2;
    width: 100%;
    height: min(68.6vw, 282.64px);
    overflow: hidden;
    border-radius: 10px;
}

.ronda-plan-row-1 {
    width: fit-content;
    height: min(62.19vw, 256.26px); 
    gap: 2px;
}

.ronda-plan-container {
    display: flex;
    gap: min(0.2vw, 3px);
    padding-top: min(5.59vw, 23.05px);
    flex-direction: column;
    width: min(83.49vw, 344px);
}

.ronda-plan-column-1 {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.ronda-plan-row-2 {
    display: flex;
    gap: 2px;
    width: 100%;
    
}

.cover-eating {
    width: 100%;
    height: min(62.19vw, 256.26px);
}

.cover-pool-image-wrapper {
    width: 50%;
    height: min(62.37vw,257px);
}

.cover-pool {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.cover-girl {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 15% 20%;
    border-radius: min(2.22vw, 32px);
    
}

.ronda-plan-description-column-wrapper {
    width: 100%;
    height: min(68.44vw, 282px);
    border-radius: min(2.22vw, 32px);
    display: flex;
    align-items: center;
    background-color: #212121;
}

.ronda-plan-description-container {
    display: flex;
    flex-direction: column;
    gap: min(5.41vw, 78px);
    padding-left: min(9.22vw, 38px);
    max-width: min(44.66vw, 184px);
}

.plan-description-title {
    font-family: 'Archivo-Expanded-Bold';
    font-size: min(5.85vw, 24px);
    line-height: min(6.33vw, 26.11px);
    text-transform: uppercase;
    letter-spacing: -1.9px;
    color: white;
}

.plan-description-subtitle {
    font-family: 'Archivo Narrow';
    font-size: min(3.88vw, 16px);
    line-height: min(5.58vw, 23px);
    color: white;
}

/*Que es ronda*/

.que-es-ronda-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: min(20.35vw, 83.86px);
}

.que-es-ronda-header-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.qer-title-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: min(2.7vw, 39px);
}

.que-es-ronda-title {
    font-family: 'Archivo-Expanded-Bold';
    font-size: min(3.88vw, 16px);
    line-height: 100%;
    color: #8F8F8F;
    letter-spacing: -1px;
    text-transform: uppercase;
}

.qer-description {
    font-family: 'Archivo-Expanded';
    font-size: min(4.36vw, 18px);
    line-height: min(7.28vw, 30px);
    letter-spacing: -1px;
    color: white;
    text-align: center;
    max-width: 344px !important;
}

.spinner-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: min(1.52vw, 22px);
    padding-top: min(2.22vw, 32px);
    height: min(34.4vw, 142px);
    width: 100%;
    margin-bottom: 35px;
}

.spinner-container {
    width: min(21.8vw, 89.84px);
    height: min(16.94vw, 69.83px);
    z-index: 1;
    
}

.background-marquee {
    position: absolute;
    top: 0;
    z-index: 0;
}

.marquee-logo-wrapper {
    width: 700px;
    height: min(34.4vw, 142px);
    
}



.cards-container {
    display: flex;
    flex-direction: column;
    gap: min(0.27vw, 4px);
}

.map-home-wrapper {
    margin-top: min(24.8vw, 102.21px);
}

/*conoce el espacio*/

.conoce-el-espacio-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: min(32vw, 132px);
}

.espacio-img-container {
    display: flex;
    flex-direction: column;
    gap: min(1.11vw, 16px);
    margin-top: min(3.33vw, 48px);
}

.espacio-img-1 {
    width: min(91.83vw, 378.36px);
    height: min(95.77vw, 394.61px);
}

.espacio-column-2-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: min(1.11vw, 16px);
}

.espacio-grid-item {
    width: min(45vw, 185.54px);
    height: min(46.71vw, 192.46px);
}

/**/

.near-footer-text {
    font-family: 'Archivo-Expanded';
    font-size: min(4.36vw, 18px);
    line-height: min(7.28vw, 30px);
    text-align: center;
    color: white;
    
}

.home-cards-slider-wrapper {
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.home-cards-slider-arrows {
    align-self: center;
}

.onp-button-mobile-home {
    background-color: white !important;
    color: #000;
}



    
}