:root {
    --24px-mobile: min(5.85vw, 24px);
    --26px-mobile: min(6.33vw, 26.11px);
    --14px-mobile: min(3.39vw, 14px);
}

.explora-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.explora-title {    
    font-family: 'Archivo-Expanded-Bold';
    color: #8F8F8F;
    font-size: min(1.66vw, 24px);
    letter-spacing: -1px;
}

.explora-cards-container {
    display: flex;
    gap: min(0.27vw, 4px);
}

.explora-left-card-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: min(57.7vw, 831px);
    height: min(47.2vw, 680px);
    border-radius: min(2.22vw, 32px);
    background-color: #0A66B0;
}

.header-wrapper {
    width: min(48.6vw, 701px);
    height: (2.36vw, 34px);
}

.explora-left-card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: min(5.36vw, 77.26px);
    
}


.cover-image-wrapper {
    width: 100%;
    height: min(19.79vw, 285px);
    position: relative;
}

.cover-image {
    /*
    position: absolute;
    left: -20%;
    top: max(-6.38vw, -92px);
    */
    width: min(50vw, 720px);
    height: min(36.4vw, 525px);
}

.bottom-content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: min(3.33vw, 48px);
}

.bottom-text {
    max-width: min(36vw, 520px);
    text-align: center;
    color: white;
    font-size: min(1.66vw, 24px);
    line-height: min(2.4vw, 35px);
    font-family: 'Archivo Narrow', 'Archivo-Expanded'; /*should be archivo narrow*/
}

.explora-right-card {
    width: min(32.6vw, 470px);
    height: min(47.2vw, 680px);
    border-radius: min(2.22vw, 32px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.explora-right-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.right-header-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: min(1.66vw, 24px);
}

.right-header-text {
    font-family: 'Archivo-Expanded';
    font-size: min(0.97vw, 14px);
    letter-spacing: 2px;
    color: white;
    text-transform: uppercase;
}

.right-header-title {
    font-family: 'Archivo-Expanded-Bold';
    font-size: min(2.63vw, 38px);
    line-height: min(2.87vw, 41.34px);
    letter-spacing: -1px;
    color: white;
    font-weight: 800;
    text-align: center;
}

.right-bottom-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: min(2.08vw, 30px);
    gap: min(2.08vw, 30px);
}

.bottom-right-text {
    font-family: 'Archivo-Expanded-Light';
    font-size: min(1.66vw, 24px);
    line-height: min(2.15vw, 31px);
    color: white;
    letter-spacing: -0.5px;
    text-align: center;
    max-width: min(20.5vw, 290px);
}

.eventos-mobile-right-header-title {
    max-width: min(26.8vw, 386px);
}


.champagne-img {
    width: min(30.97vw, 446px);
    height: min(31.11vw, 448px);
}

@media screen and (max-width: 478px) {
    
.champagne-img {
    width: min(46.84vw, 193px);
    height: min(48.5vw, 199.86px);
}

.explora-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.explora-wrapper-margin-mobile {
    margin-top: min(31.55vw, 130px) !important;
}

.explora-title {    
    font-family: 'Archivo-Expanded-Bold';
    color: #8F8F8F;
    font-size: min(1.66vw, 24px);
    letter-spacing: -1px;
}

.explora-cards-container {
    display: flex;
    gap: 3px;
    flex-direction: column;
    width: min(91.01vw, 375px);
}

.explora-left-card-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: min(87.37vw, 360px);
    border-radius: min(2.22vw, 32px);
    background-color: #0A66B0;
    order: 1;
}

.header-wrapper {
    width: min(85.4vw, 352px);
    height: min(4.14vw, 17px);
}

.explora-left-card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: min(7.85vw, 32.38px);
    gap: min(6.31vw, 26px);
}


.cover-image {
    width: min(63.8vw, 265px);
    height: min(47.3vw, 195px);
    position: static;
}

.cover-image-wrapper {
    width: auto;
    height: auto;
    position: relative;
}

.adjust-gap {
    gap: 0px !important;
}

.bottom-content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: min(6.31vw, 26px);
}

.bottom-text {
    max-width: min(73vw, 301px);
    text-align: center;
    color: white;
    font-size: min(3.88vw, 16px);
    line-height: min(5.58vw, 23px);
    font-family: 'Archivo Narrow', 'Archivo-Expanded'; /*should be archivo narrow*/
}

.explora-right-card {
    width: 100%;
    height: min(56.79vw, 234px);
    border-radius: min(2.22vw, 32px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.explora-right-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.right-header-container {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    gap: min(7.28vw, 30px);
}

.right-header-text {
    font-family: 'Archivo-Expanded';
    font-size: var(--14px-mobile);
    letter-spacing: 2px;
    color: white;
    text-transform: uppercase;
}

.right-header-title {
    font-family: 'Archivo-Expanded-Bold';
    font-size: var(--24px-mobile);
    line-height: var(--26px-mobile);
    letter-spacing: -3px;
    color: white;
    font-weight: 800;
    text-align: center;
}

.eventos-mobile-right-header-title {
    max-width: 291px;
}

.right-bottom-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: min(7.28vw, 30px);
    gap: 0px;
}

.bottom-right-text {
    display: none;
    font-family: 'Archivo-Expanded-Light';
    font-size: min(1.66vw, 24px);
    line-height: min(2.15vw, 31px);
    color: white;
    letter-spacing: -0.5px;
    text-align: center;
    max-width: min(20.5vw, 290px);
}


    
}