.map-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: min(11.7vw, 169px);
}

.map-tile-container {
    width: min(62.9vw, 906px);
    height: min(33.75vw, 486px);
    border-radius: min(2.22vw, 32px);
    overflow: hidden;
    margin-top: min(2.77vw, 40px);
}

.corner-logo-wrapper {
    position: absolute;
    top: -15%;
    left: 5%;
    width: min(10.6vw, 153px);
    /*height: min(4.65vw, 66.97px);*/
    height: auto;
}

.map-info-container {
    display: flex;
    flex-direction: column;
    margin-top: min(2.77vw, 40px);
    gap: min(2.63vw, 38px);
    align-items: center;
}

.map-description {
    font-family: 'Archivo Narrow', 'Archivo-Expanded';
    font-size: min(1.66vw, 24px);
    line-height: min(2.43vw, 35px);
    color: white;
    text-align: center;
    
}

.map-button-row {
    display: flex;
    gap: min(0.55vw, 8px);
}

@media screen and (max-width: 478px) {
    
    .map-wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: min(11.7vw, 169px);
    }
    
    .map-tile-container {
        width: 100vw;
        height: min(87.13vw, 359px);
        border-radius: 0;
        overflow: hidden;
        margin-top: min(2.77vw, 40px);
        overflow: hidden;
    }
    
    .corner-logo-wrapper {
        position: relative;
        width: min(27.15vw, 111.87px);
        height: auto;
        order: -1;
        margin-bottom: min(4.13vw, 17px);
        margin-right: 15%;
    }
    
    .map-info-container {
        display: flex;
        flex-direction: column;
        margin-top: min(6vw, 25px);
        gap: min(12.6vw, 52px);
        align-items: center;
    }
    
    .map-description {
        font-family: 'Archivo Narrow', 'Archivo-Expanded';
        font-size: min(3.88vw, 16px);
        line-height: min(5.82vw, 24px);
        color: white;
        text-align: center;
        max-width: 268px;
        
    }
    
    .map-button-row {
        display: flex;
        gap: 5px;
    }
    
}