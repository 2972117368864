.footer-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: min(4.47vw, 64.5px);
    margin-top: 100px;
    padding-bottom: min(5.34vw, 77px);
}

.footer-margin-entertainment {
    margin-top: min(4.47vw, 64.5px);
}

.footer-boxes-wrapper {
    display: flex;
    gap: min(0.41vw, 6px);
}

.item-baseline {
    background-color: #131313;
    border-radius: min(0.55vw, 8px);
    transition: background-color ease 0.5s;
}

.item-wrapper {
    display: flex;
    flex-direction: column;
    padding-top: min(1.94vw, 28px);
    padding-left: min(1.52vw, 22px);
    gap: min(1.66vw, 24px);
}

.item-baseline:hover {
    background-color: rgb(10, 10, 10);
}

.footer-item-text {
    font-family: 'Archivo-Expanded-Bold';
    font-size: min(2.22vw, 32px);
    line-height: min(2.22vw, 32px);
    letter-spacing: -2.5px;
    color: white;
    text-transform: uppercase;
}

.arrow-wrapper {
    width: min(2.8vw, 41.4px);
    height: min(2.7vw, 39px);
    transition: padding ease 0.5s;
}

.column-1-wrapper {
    display: flex;
    flex-direction: column;
    gap: min(0.41vw, 6px);
}

.socials-row {
    display: flex;
    align-items: center;
    justify-content: center;
    width: min(32.94vw, 475px);
    height: min(5.2vw, 75px);
}

.socials-container {
    display: flex;
    align-items: center;
    gap: min(3.12vw, 45px);
}

.logo {
    transition: opacity ease 0.5s;
}

.logo:hover {
    opacity: 0.5;
}

.insta-logo {
    width: min(1.99vw, 28.7px);
    height: min(1.99vw, 28.7px);
}


.tiktok-logo {
    width: min(1.82vw, 26.28px);
    height: min(2vw, 28.89px);
}

.fb-logo {
    width: min(1.93vw, 27.86px);
    height: min(1.92vw, 27.7px);
}

.email-logo {
    width: auto;
    height: min(1.99vw, 28.7px);
}

.back-to-top-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: min(1.77vw, 26px);
}

.back-to-top-text {
    font-family: 'Archivo-Expanded' !important;
}

.row-wrapper {
    display: flex;
    gap: min(0.41vw, 6px);
}

.dot-logo {
    /*
    width: min(10.6vw, 163px);
    height: min(4.65vw, 66.97px);
    */
    width: min(13.19vw, 190px);
    height: auto;
}

.footer-bottom-text-container {
    margin-top: min(5.2vw, 75px);
}

.footer-bottom-text {
    font-family: 'Mont', 'Archivo-Expanded';
    font-size: min(0.97vw, 14px);
    line-height: 100%;
    color: #7A7A7A;
}

@media screen and (max-width: 478px) {

    .footer-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: min(4.47vw, 64.5px);
        margin-top: min(31.55vw, 130px);
        padding-bottom: min(10.92vw, 45px);
    }
    
    .footer-margin-entertainment {
        margin-top: min(4.47vw, 64.5px);
    }
    
    .footer-boxes-wrapper {
        display: flex;
        gap: 3px;
        flex-direction: column;
        width: min(96.11vw, 396px);
    }
    
    .item-baseline {
        background-color: #131313;
        border-radius: 5.88px;
        transition: background-color ease 0.5s;

    }

    .added-padding {
        padding-bottom: min(5.41vw, 22.31px);
        padding-top: min(5.41vw, 22.31px);
    }
    
    .item-wrapper {
        display: flex;
        flex-direction: column;
        padding-top: min(6.79vw, 28px);
        padding-left: min(5.33vw, 22px);
        gap: min(4.85vw, 20px);
    }
    
    .item-baseline:hover {
        background-color: rgb(10, 10, 10);
    }
    
    .footer-item-text {
        font-family: 'Archivo-Expanded-Bold';
        font-size: min(3.88vw, 16px);
        line-height: min(4.54vw, 18.73px);
        letter-spacing: -0.5px;
        color: white;
        text-transform: uppercase;
    }
    
    .arrow-wrapper {
        width: min(6.79vw, 28px);
        height: min(6.4vw, 26.38px);
        transition: padding ease 0.5s;
    }

    .horizontal-arrow-wrapper {
        width: min(10vw, 41.4px);
        height: min(9.46vw, 39px);
    }
    
    .column-1-wrapper {
        display: flex;
        flex-direction: column;
        gap: 3px;
    }
    
    .socials-row {
        display: flex;
        align-items: center;
        justify-content: center;
        width: min(47.62vw, 196.2px);
        height: min(10.92vw, 45px);
        align-self: center;
        background-color: #131313;
        border-radius: 5.88px;
    }
    
    .socials-container {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: min(3.12vw, 45px);
    }
    
    .logo {
        transition: opacity ease 0.5s;
        
    }
    
    .logo:hover {
        opacity: 0.5;
    }
    
    .insta-logo {
        width: min(4.17vw, 17.22px);
        height: min(4.17vw, 17.22px);
    }
    
    
    .tiktok-logo {
        width: min(3.82vw, 15.77px);
        height: min(4.2vw, 17.33px);
    }
    
    .fb-logo {
        width: min(4.05vw, 16.71px);
        height: min(4.03vw, 16.62px);
    }
    
    .email-logo{
        height: initial;
        width: auto;
        height: min(4.17vw, 17.22px);
        
    }
        
    .back-to-top-flex {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 6vw;
        padding-top: 0 !important;
       
    }

    .just-self {
        
        align-self: flex-end !important;
        justify-self: flex-end !important;
    }
    .back-to-top-text {
        font-family: 'Archivo-Expanded' !important;
    }
    
    .row-wrapper {
        display: flex;
        gap: min(0.41vw, 6px);
    }
    
    .dot-logo {
        width: min(10.6vw, 163px);
        height: min(4.65vw, 66.97px);
    }
    
    .footer-bottom-text-container {
        margin-top: min(10.92vw, 45px);
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
    }
    
    .footer-bottom-text {
        font-family: 'Mont', 'Archivo-Expanded';
        font-size: min(2.91vw, 12px);
        line-height: 100%;
        color: #7A7A7A;
        text-align: center;
        max-width: 90%;
    }

    .flex-row {
        display: flex;
        gap: 3px;
    }
    
    .item-wrapper-centered {
        padding-bottom: 0;
        padding-left: min(3.92vw, 16.16px);

    }
    
}