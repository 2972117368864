

/*EventForm.js*/

.event-form-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    /*width: min(45.42vw, 654.05px);*/
    align-items: center;
    justify-content: center;
    padding-top: min(4.93vw, 71px);
    padding-bottom: min(16.66vw, 240px);
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
    
   
}

.event-form-container {
    
    display: flex;
    flex-direction: column;
    gap: min(2.91vw, 41.98px);
    transition: max-height ease 0.3s, opacity ease 0.5s;
}

.event-form-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: min(0.77vw, 11.19px);
}

.event-form-input-container {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: min(0.53vw, 7.7px);
}

.event-form-label {
    color: black;
    font-family: 'Archivo-Expanded-SemiBold';
    font-size: min(0.97vw, 14px);
    
}

.event-form-input {
    width: min(22.29vw, 321px);
    
    height: min(4.09vw, 59px);
    border-radius: min(4.61vw, 66.46px);
    border: 0.7px solid;
    border-color: #8F8F8F;
    font-family: 'Archivo-Expanded';
    color: black;
    font-size: min(1.18vw, 17px);
    box-sizing: border-box;
    padding-left: 10%;
    padding-right: 10%;
    
}

.event-form-row-2 {
    display: flex;
    gap: min(1.18vw, 17px);
}

.event-form-counter-input {
    background-color: white;
    color: black;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: min(14.51vw, 209px);
    height: min(4.12vw, 59.47px);
    border: 0.7px solid;
    border-color: #8F8F8F;
    border-radius: min(4.61vw, 66.46px);
}

.sign-wrapper {
    cursor: pointer;
    width: min(1.52vw, 22px);
    height: auto;
    transition: opacity ease 0.3s;
}

.sign-wrapper:hover {
    opacity: 0.5;
}

.counter-input {
    font-family: 'Archivo-Expanded';
    font-size: min(1.18vw, 17px);
    line-height: 100%;
}

.event-form-dropdown-event-type {
    background-color: white;
    font-family: 'Archivo-Expanded';
    width: min(13.94vw, 200.79px);
    height: min(4.12vw, 59.47px);
    border-radius: min(4.61vw, 66.46px);
    border: 0.7px solid;
    border-color: #8F8F8F;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    font-size: min(0.97vw, 14px);
    gap: min(0.5vw, 7.22px);
    cursor: pointer;
    transition: opacity ease 0.3s;
}

.event-form-dropdown-event-type:hover {
    opacity: 0.5;
}

.dropdown-wrapper {
    width: fit-content;
}

.dropdown-select-container {
    background-color: white;
    display: flex;
    flex-direction: column;
    padding-left: 5%;
    width:  100%;
    margin-top: -2%;
    gap: 10px;
    font-family: 'Archivo-Expanded';
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border: 0.7px solid;
    border-color: #8F8F8F;
    position: absolute;
    top: 100%;
    box-sizing: border-box;
    z-index: 1;
}

.dropdown-option {
    padding-top: 5%;
    padding-bottom: 5%;
    cursor: pointer;
    font-size: min(0.97vw, 14px);
    width: fit-content
}

.dropdown-option:hover {
    opacity: 0.5;
}

.event-form-row-3 {
    display: flex;
    gap: min(0.48vw, 7px);
}

.event-form-date-input {
    font-family: 'Archivo-Expanded';
    background-color: white;
    width: min(9.61vw, 138.52px);
    height: min(4.12vw, 59.47px);
    border-radius: min(4.61vw, 66.46px);
    border: 0.7px solid;
    border-color: #8F8F8F;
    display: flex;
    box-sizing: border-box;
    gap: min(0.5vw, 7.22px);
    align-items: center;
    justify-content: center;
    font-size: min(0.97vw, 14px);
    cursor: pointer;
    transition: opacity ease 0.3s;
}

.event-form-date-input:hover {
    opacity: 0.5;
}

.calendar-wrapper {
    position: fixed;
    
}

.event-form-calendar-wrapper {
    position: absolute;
    display: flex;
    z-index: 1999;
    top: 100%;
}

.event-form-date-input-container {
    display: flex;
    align-items: center;
    padding: initial;
    justify-content: space-evenly;
    background-color: white;
}

.date-picker-wrapper {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: opacity ease 0.3s;
}



.time-input {
    all: initial;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 99;
}
   
  .time-input::-webkit-calendar-picker-indicator {
    width: 100%;
    height: min(4.12vw, 59.47px);
    opacity: 0;
    cursor: pointer;
  }

  .date-picker-divider {
    height: 100%;
    width: 0.7px;
    background-color: #8F8F8F;
  }

  .time-text {
    font-family: 'Archivo-Expanded';
    font-size: min(0.97vw, 14px);
    transition: opacity ease 0.3s;
  }

    .time-text:hover {
        opacity: 0.5;
    }


  .dropdown-max-height {
    max-height: 200px;
    overflow-y: auto;
    margin-top: 0px;
    
  }

.dropdown-max-height::-webkit-scrollbar {
    display: none;
}

.checkbox-grid {
    display: flex;
    flex-wrap: wrap;
    gap: min(0.24vw, 3.5px);
    max-width: 690px;
    
    

}

.checkbox-input {
    display: flex;
    align-items: center;
    justify-content: center;
    height: min(4.16vw, 59.98px);
    width: fit-content;
    padding-left: min(2.62vw, 37.78px);
    padding-right: min(2.62vw, 37.78px);
    font-family: 'Archivo-Expanded';
    font-size: min(0.97vw, 14px);
    letter-spacing: -0.5px;
    color: #404040;
    border-radius: min(4.85vw, 69.96px);
    border: 0.7px solid #8F8F8F;
    text-transform: uppercase;
    cursor: pointer;
    transition: background-color ease 0.3s;
}

.checkbox-input:hover {
    background-color: #a7d9e6;
}

.event-form-textarea {
    font-family: 'Archivo-Expanded';
    box-sizing: border-box;
    padding-left: 3%;
    padding-top: 1%;
    padding-right: 3%;
    padding-bottom: 1%;
    width: 100%;
    height: min(10.06vw, 145px);
    border: 0.7px solid #8F8F8F;
    line-height: 130%;
    border-radius: min(2.91vw, 41.98px);
    overflow-y: auto;
    resize: none;
}


.event-form-submit-button {
    all: initial;
    padding-top: min(1.21vw, 17.49px);
    padding-right: min(2.77vw, 40px);
    padding-bottom: min(1.21vw, 17.49px);
    padding-left: min(2.77vw, 40px);
    font-family: 'Archivo-Expanded-Light';
    font-weight: 400;
    font-size: min(1.11vw, 16px);
    line-height: min(2.6vw, 37.47px);
    background-color: black;
    color: white;
    border-radius: min(13.88vw, 200px);
    transition: background-color ease 0.3s, color ease 0.5s;
    width: fit-content;
    cursor: pointer;
    align-self: flex-end;
    text-transform: uppercase;
    font-weight: 300;
}

.event-form-submit-button:hover {
    background-color: white;
    color: black;
}

.section-title-spacing {
    margin-top: min(12.5vw, 180px);
}

.calendar-input {
    position: absolute;
    opacity: 0;
    top: 50%;
    pointer-events: none;
}

.submit-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.form-success {
    position: absolute;
    width: 100%;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    top: 0;
    align-items: center;
    justify-content: center;
    transition: opacity ease 0.3s;
    font-family: 'Archivo-Expanded';
    font-size: 20px;
    text-align: center;
    box-sizing: border-box;
}

.checkmark-wrapper {
    width: 100px;
    height: auto;
}

.date-error {
    width: fit-content !important;
    color: black;
    font-family: 'Archivo-Expanded-SemiBold';
    font-size: 14px;
    position: absolute;
    top: 110%;
    left: 0;
    z-index: 9;
    text-wrap: nowrap;

}

@media screen and (max-width: 478px) {

/*EventForm.js*/


.submit-row {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
}

.event-form-submit-button {
    justify-self: initial;
    align-self: initial !important;
}

.event-form-wrapper {
    display: flex;
    flex-direction: column;
    /*width: min(45.42vw, 654.05px);*/
    align-items: center;
    justify-content: center;
    padding-top: min(21.8vw, 90px);
    padding-bottom: min(30.8vw, 127px);
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
   
}

.event-form-container {
    
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: min(7.28vw, 30px);
}

.event-form-row {
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 30px;
    margin-bottom: 30px;
}

.event-form-input-container {

    display: flex;
    flex-direction: column;
    gap: min(2.42vw, 10px)
}

.event-form-input-container-mobile-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: min(83.49vw, 344px);
    justify-content: space-between;

}

.label-max-width-mobile {
    max-width: min(22.33vw, 92px);
    word-wrap: break-word;
}

.event-form-label {
    color: black;
    font-family: 'Archivo-Expanded-SemiBold';
    font-size: min(3.39vw, 14px);
    
}

.event-form-input {
    width: min(83.49vw, 344px);    
    height: min(15.94vw, 65.71px);
    border-radius: min(17.82vw, 73.44px);
    border: 0.7px solid;
    border-color: #8F8F8F;
    font-family: 'Archivo-Expanded';
    color: black;
    font-size: min(3.39vw, 14px);
    box-sizing: border-box;
    padding-left: 10%;
    padding-right: 10%;
    
}

.event-form-row-2 {
    display: flex;
    gap: min(7.28vw, 30px);
    flex-direction: column;
}

.event-form-counter-input {
    background-color: white;
    color: black;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: min(54.08vw, 222.85px);
    height: min(16.01vw, 66px);
    border: 0.7px solid;
    border-color: #8F8F8F;
    border-radius: min(17.9vw, 73.76px);
}

.sign-wrapper {
    cursor: pointer;
    width: min(3.92vw, 16.17px);
    height: auto;
    transition: opacity ease 0.3s;
}

.sign-wrapper:hover {
    opacity: 0.5;
}

.counter-input {
    font-family: 'Archivo-Expanded';
    font-size: min(3.39vw, 14px);
    line-height: 100%;
}

.event-form-dropdown-event-type {
    background-color: white;
    font-family: 'Archivo-Expanded';
    width: min(83.49vw, 344px);
    height: min(15.77vw, 65px);
    border-radius: min(17.63vw, 72.65px);
    border: 0.7px solid;
    border-color: #8F8F8F;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    font-size: min(3.39vw, 14px);
    gap: min(0.5vw, 7.22px);
    cursor: pointer;
    transition: opacity ease 0.3s;
    padding-left: min(5vw, 21px);
    padding-right: min(5vw, 21px);
}

.event-form-dropdown-event-type:hover {
    opacity: 0.5;
}

.dropdown-wrapper {
    width: fit-content;
}

.dropdown-select-container {
    background-color: white;
    display: flex;
    flex-direction: column;
    padding-left: 5%;
    width:  100%;
    margin-top: -2%;
    gap: 10px;
    font-family: 'Archivo-Expanded';
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border: 0.7px solid;
    border-color: #8F8F8F;
    position: absolute;
    top: 100%;
    box-sizing: border-box;
    z-index: 1;
}

.dropdown-option {
    padding-top: 5%;
    padding-bottom: 5%;
    cursor: pointer;
    font-size: min(3.39vw, 14px);
    width: fit-content
}

.dropdown-option:hover {
    opacity: 0.5;
}

.event-form-row-3 {
    display: flex;
    gap: min(7.28vw, 30px);
    flex-direction: column;
}

.event-form-date-input {
    background-color: white;
    font-family: 'Archivo-Expanded';
    width: min(83.49vw, 344px);
    height: min(15.77vw, 65px);
    border-radius: min(17.63vw, 72.65px);
    border: 0.7px solid;
    border-color: #8F8F8F;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    font-size: min(3.39vw, 14px);
    gap: min(0.5vw, 7.22px);
    cursor: pointer;
    transition: opacity ease 0.3s;
    padding-left: min(5vw, 21px);
    padding-right: min(5vw, 21px);
}

.event-form-date-input:hover {
    opacity: 0.5;
}

.calendar-wrapper {
    position: fixed;
    
}

.event-form-calendar-wrapper {
    position: absolute;
    display: flex;
    z-index: 1999;
    top: 100%;
}

.event-form-date-input-container {
    display: flex;
    align-items: center;
    padding: initial;
    justify-content: space-evenly;
    background-color: white;
}

.date-picker-wrapper {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: opacity ease 0.3s;
}



.time-input {
    all: initial;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 99;
}
   
  .time-input::-webkit-calendar-picker-indicator {
    width: 100%;
    height: min(4.12vw, 59.47px);
    opacity: 0;
    cursor: pointer;
  }

  .date-picker-divider {
    height: 100%;
    width: 0.7px;
    background-color: #8F8F8F;
  }

  .time-text {
    font-family: 'Archivo-Expanded';
    font-size: min(3.39vw, 14px);
    transition: opacity ease 0.3s;
  }

    .time-text:hover {
        opacity: 0.5;
    }


  .dropdown-max-height {
    max-height: 200px;
    overflow-y: auto;
    margin-top: 0px;
    
  }

.dropdown-max-height::-webkit-scrollbar {
    display: none;
}

.checkbox-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 2px;
    max-width: min(82.7vw, 341px);
    
    

}

.checkbox-input {
    display: flex;
    align-items: center;
    justify-content: center;
    height: min(11.65vw, 48px);
    width: fit-content;
    padding-left: min(5.58vw, 23px);
    padding-right: min(5.58vw, 23px);
    font-family: 'Archivo-Expanded';
    font-size: min(3.39vw, 14px);
    letter-spacing: -0.5px;
    color: #404040;
    border-radius: min(24.2vw, 100px);
    border: 0.7px solid #8F8F8F;
    text-transform: uppercase;
    cursor: pointer;
    transition: background-color ease 0.3s;
}

.checkbox-input:hover {
    background-color: #a7d9e6;
}

.event-form-textarea {
    font-family: 'Archivo-Expanded';
    box-sizing: border-box;
    padding-left: 3%;
    padding-top: 1%;
    padding-right: 3%;
    padding-bottom: 1%;
    width: 100%;
    height: min(50.31vw, 207.3px);
    border: 0.7px solid #8F8F8F;
    line-height: 130%;
    border-radius: min(3.88vw, 16px);
    overflow-y: auto;
    resize: none;
}


.event-form-submit-button {
    all: initial;
    padding-top: min(1.91vw, 8px);
    padding-right: min(6.31vw, 26px);
    padding-bottom: min(1.91vw, 8px);
    padding-left: min(6.31vw, 26px);
    font-family: 'Archivo-Expanded-Light';
    font-weight: 400;
    font-size: min(3.39vw, 14px);
    line-height: 35px;
    background-color: black;
    color: white;
    border-radius: min(13.88vw, 200px);
    transition: background-color ease 0.3s, color ease 0.5s;
    width: fit-content;
    cursor: pointer;
    align-self: flex-end;
    text-transform: uppercase;
    font-weight: 300;
}

.event-form-submit-button:hover {
    background-color: white;
    color: black;
}

.section-title-spacing {
    margin-top: min(31.55vw, 130px);
}

.calendar-input {
    position: absolute;
    opacity: 0;
    top: 50%;
    pointer-events: none;
}
    
}