.insta-row-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: min(11.7vw, 169px);
    
}

.insta-header-container {
    display: flex;
    align-items: center;
    width: min(100vw, 1440px);
    gap: 26px;
    justify-content: space-around;
}

.ronda-insta-svg-wrapper {
    width: min(13vw, 188px);
    height: min(4.23vw, 61px);
}

.ronda-insta-header {
    font-family: "Archivo-Expanded";
    color: #8F8F8F;
    font-weight: 700;
    font-size: min(2.28vw, 32px);
}

.separating-line {
    height: min(0.2vw, 3px);
    flex-grow: 1;
    background-color: #8F8F8F;
}

.insta-posts-row {
    display: flex;
    max-width: 100vw;
    gap: -20px;
    margin-top: min(2.33vw, 33.57px);
}

.post-container {
    margin-left: -1vw;
}

.insta-post {
    width: min(22.08vw, 318px);
    height: min(22.08vw, 318px);
}

@media screen and (max-width: 478px) {


    .ronda-insta-header {
        font-family: "Archivo-Expanded";
        color: #8F8F8F;
        font-weight: 700;
        font-size: 18px;
    }

    .insta-row-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: min(21.6vw, 89px);
    }
    
    .insta-header-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: min(2.63vw, 38px);
        padding-left: 15%;
        padding-right: 15%;
        
    }
    
    .ronda-insta-svg-wrapper {
        width: auto;
        height: 15px;
    }
    
    .separating-line {
        height: min(0.2vw, 3px);
        width: min(64vw, 922px);
        background-color: #8F8F8F;
    }
    
    .insta-posts-row {
        display: flex;
        max-width: 100vw;
        gap: -20px;
        margin-top: min(7.76vw, 32px);
    }
    
    .post-container {
        margin-left: -1vw;
    }

    .insta-post {
        width: min(36.19vw, 149.14px);
        height: min(36.19vw, 149.14px);
    }
    
}