.service-title-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: min(2.22vw, 32px);
    padding-top: min(5.83vw, 84px);
}

.service-title {
    font-family: 'Archivo-Expanded-SemiBold';
    font-size: min(6.52vw, 94px);
    line-height: 100%;
    color: white;
    letter-spacing: -7px;
}

.service-text {
    font-family: 'Archivo-Expanded-Light';
    color: white;
    text-align: center;
    max-width: min(53.5vw, 771px);
    font-size: min(1.6vw, 24px);
    line-height: min(2.15vw, 31px); /*good*/
}

/*for events*/

.service-title-dark-padding {
    padding-top: min(11.46vw, 165.05px);
}

@media screen and (max-width: 478px) {

    .service-title-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: min(10.43vw, 43px);
        padding-top: min(13.11vw, 54.05px);
    }
    
    .service-title {
        font-family: 'Archivo-Expanded-SemiBold';
        font-size: min(7.76vw, 32px);
        line-height: 100%;
        color: white;
        letter-spacing: -2px;
    }
    
    .service-text {
        font-family: 'Archivo-Expanded-Light';
        color: white;
        text-align: center;
        max-width: min(83.49vw, 344px);
        font-size: min(3.39vw, 14px);
        line-height: min(6.96vw, 28.7px); /*good*/
    }
    
    /*for events*/
    
    .service-title-dark-padding {
        padding-top: min(11.46vw, 165.05px);
    }
    
}