.nav-wrapper {
    display: flex;
    gap: min(3.47vw, 50px);
    align-items: center;
    padding-top: min(3.5vw, 51px);
    position: relative;
    /*width: min(100vw, 1440px);
    width: min(76.9vw, 1108px);
    justify-content: space-between;
    padding-left: min(9.09vw, 131px);*/
    color: white;
    

}

.nav-module {
    display: flex;
    align-items: center;
    gap: min(1.9vw, 28px);
    font-family: 'Archivo-Expanded';
    font-size: min(0.97vw, 14px);
    line-height: min(1.3vw, 19px);
    letter-spacing: 15%;
    text-decoration: none !important;
    text-decoration-line: none;
    text-transform: uppercase;
    /*color: white;*/
    cursor: pointer;
}



.blue {
    background-color: blue;
}

.nav-mid-logo {
    width: min(8.05vw, 116px);
    height: min(1.59vw, 22.95px);
   
}


.absolute-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.module-left {
    
}

.module-right {
    gap: min(3.33vw, 48px);
}


@media screen and (max-width: 478px) {

    .nav-wrapper {
        display: flex;
        gap: min(3.47vw, 50px);
        align-items: center;
        padding-top: min(7.76vw, 32px);
        position: relative;
        /*width: min(100vw, 1440px);
        width: min(76.9vw, 1108px);
        justify-content: space-between;
        padding-left: min(9.09vw, 131px);*/
        color: white;
        
    
    }
    
    .nav-module {
        display: flex;
        align-items: center;
        gap: min(1.9vw, 28px);
        font-family: 'Archivo-Expanded';
        font-size: min(0.97vw, 14px);
        line-height: min(1.3vw, 19px);
        letter-spacing: 15%;
        text-decoration: none !important;
        text-decoration-line: none;
        text-transform: uppercase;
        /*color: white;*/
        cursor: pointer;
    }
    
    
    
    .blue {
        background-color: blue;
    }
    
    .nav-mid-logo {
        width: min(28.15vw, 116px);
        height: min(5.57vw, 22.95px);
       
    }
    
    
    .absolute-wrapper {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .module-left {
        
    }
    
    .module-right {
        gap: min(3.33vw, 48px);
    }
    
    
    
    
}
