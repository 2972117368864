.saborea-right-card-content {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    gap: min(0.625vw, 9px);
    justify-content: space-between;
}

.salad-wrapper {
    width: 100%;
    height: 80%;
    position: relative;
}


.salad-img {
    width: min(47vw, 677px);
    height: min(36.5vw, 527px);
    z-index: 0;
    margin-top: 5%;
}

.buttons-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: min(0.45vw, 6.57px);
    z-index: 1;
    justify-self: flex-end;
    max-width: min(27.7vw, 400px);
}

/*unify css files for cards later*/

/*moda card*/

.moda-left-card-content {
    display: flex;
    flex-direction: column;
    padding-top: min(10.1vw, 145.44px);
    height: 100%;
    align-items: center;
    justify-content: space-between;
    
}

.moda-card-brand-row {
    display: flex;
    align-items: center;
    gap: min(2.22vw, 32px);
}

.kuyen-wrapper {
    width: min(31.4vw, 453px);
    height: min(5vw, 72px);
}

.delucca-wrapper {
    width: min(12vw, 173px);
    height: min(1.84vw, 26.6px);
}

.dionis-wrapper {
    width: min(8.3vw, 119.9px);
    height: min(3.7vw, 54.25px);
}

.moda-avatar {
    width: min(42.22vw, 608px);
    height: min(41.45vw, 597px);
    margin-top: -14%;
    margin-left: 10%;
    
}

/*observa*/

.observa-right-card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    
}

.observa-card-img-row {
    display: flex;
    gap: min(0.55vw, 8px);
}

.observa-card-miniature {
    width: min(17.36vw, 250px);
    height: min(17.36vw, 250px);
}

.observa-card-img {
    width: 100%;
    height: auto;
}

@media screen and (max-width: 478px){
    
    .saborea-right-card-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        width: 100%;
        gap: 4px;
        justify-content: center;
    }
    
    .salad-wrapper {
        width: auto;
        height: auto;
        position: relative;
        margin-top: -10%;
        margin-left: 0;
    }
    
    .salad-img {
        position: static;
        width: 100%;
        height: auto;
        margin-left: 0;
        
    }
    
    .buttons-wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 3px;
        justify-content: center;
        padding-left: 2px;
        padding-right: 2px;
        max-width: none;
    }
    
    /*unify css files for cards later*/
    
    /*moda card*/
    
    .moda-left-card-content {
        display: flex;
        flex-direction: column;
        padding-top: min(10.1vw, 145.44px);
        height: 100%;
        align-items: center;
        justify-content: space-between;
        
    }
    
    .moda-card-brand-row {
        display: flex;
        align-items: center;
        gap: min(2.22vw, 32px);
    }
    
    .kuyen-wrapper {
        width: min(31.4vw, 453px);
        height: min(5vw, 72px);
    }
    
    .delucca-wrapper {
        width: min(12vw, 173px);
        height: min(1.84vw, 26.6px);
    }
    
    .dionis-wrapper {
        width: min(8.3vw, 119.9px);
        height: min(3.7vw, 54.25px);
    }
    
    .moda-avatar {
        width: min(69vw, 288.37px);
        height: min(69vw, 285px);
        
    }
    
    /*observa*/
    
    .observa-right-card-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
    }
    
    .observa-card-img-row {
        display: flex;
        gap: min(0.55vw, 8px);
    }
    
    .observa-card-miniature {
        width: min(27.91vw, 115px);
        height: min(27.91vw, 115px);
    }

    .observa-card-img {
        width: 100%;
        height: auto;
    }
}